import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../css/Header.css";
import ManagerApi from "../api/managerApi";

const Header = () => {
  let navigate = useNavigate();
  const [managerEmail, setManagerEmail] = useState(null);

  useEffect(() => {
    const managerId = Number(sessionStorage.getItem("id"));
    getManager(managerId);
  }, []);

  const getManager = async (id) => {
    try {
      const info = (await ManagerApi.Get(id)).data.data.content;
      setManagerEmail(info.name);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="admin-header">
      <div className="admin-header-item">
        <img src="/img/header-profile-img.svg"></img>
        <span>{managerEmail}</span>
        <button
          onClick={() => {
            navigate("/admin/login");
            sessionStorage.clear();
          }}
        >
          로그아웃
        </button>
      </div>
    </div>
  );
};

export default Header;
