import React, { useState } from "react";
import "../../css/registration/ManageRegistration.css";
import ManagerApi from "../../api/managerApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ManageRegistration = () => {
  let navigate = useNavigate();
  const [password1, setPassword1] = useState(null);
  const [password2, setPassword2] = useState(null);
  let reg = /^(?!((?:[A-Z]+)|(?:[a-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]{8,16}$/;
  let regEmail = /^[a-zA-Z0-9+-_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  const [joinData, setJoinData] = useState({
    email: null,
    password: null,
    name: null,
    managerAuthority: "MANAGER",
    accessMenu: "[space-member, meeting, program, mileage]",
  });

  const onSubmitHandler = async () => {
    try {
      if (!joinData.name) {
        toast("이름을 입력해주세요.");
        return;
      }
      if (!joinData.email) {
        toast("이메일을 입력해주세요.");
        return;
      }
      if (!joinData.password) {
        toast("비밀번호를 입력해주세요.");
        return;
      }
      if (!password2) {
        toast("비밀번호 확인란을 채워주세요.");
        return;
      }
      if (!regEmail.test(joinData.email)) {
        toast("올바른 이메일 형식이 아닙니다.");
        return;
      }
      if (!reg.test(password1)) {
        toast("비밀번호는  영문 대문자/영문 소문자/숫자/특수문자 중 2가지 이상이 조합되어야 합니다.");
        return;
      }
      if (password1 !== password2) {
        toast("비밀번호가 일치하지 않습니다");
        return;
      } else {
        await ManagerApi.Join(joinData);
        navigate("/admin/join/success");
      }
    } catch (err) {
      const response = err.response.data.message;
      if (typeof response === "object") {
        response.map((item, index) => {
          toast(item);
        });
        return;
      }
      if (typeof response === "string") {
        toast(response);
        return;
      }
    }
  };

  return (
    <div className="Manage-registration-container">
      <div className="Manage-registration">
        <h1>회원가입</h1>
        <div className="Manage-registration-form">
          <span>이름</span>
          <input
            type="text"
            name="name"
            required
            placeholder="이름을 입력해 주세요."
            onChange={(e) =>
              setJoinData({
                ...joinData,
                name: e.target.value,
              })
            }
          />

          <span>이메일</span>
          <input
            type="email"
            name="email"
            required
            placeholder="이메일을 입력해 주세요."
            onChange={(e) =>
              setJoinData({
                ...joinData,
                email: e.target.value,
              })
            }
          />
          <span>비밀번호</span>
          <input
            type="password"
            name="password"
            required
            placeholder="비밀번호를 입력해 주세요."
            onChange={(e) => {
              setPassword1(e.target.value);
              setJoinData({
                ...joinData,
                password: e.target.value,
              });
            }}
          />
          <div className="Manage-registration-form-pw-note">
            <span>
              조합: 영문 대문자/영문 소문자/숫자/특수문자 중 2가지 이상
              <br />
              글자수: 8자~16자
            </span>
          </div>
          <span>비밀번호 확인</span>
          <input
            type="password"
            name="password2"
            required
            placeholder="비밀번호를 한 번 더 입력해 주세요."
            onChange={(e) => setPassword2(e.target.value)}
          />
          <button className="Manage-registration-form-registration cursorPointer" onClick={onSubmitHandler}>
            회원가입
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManageRegistration;
