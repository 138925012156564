import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import "../../css/catagory/ManageCatagory.css";
import "../../css/Manage.css";
import FaqApi from "../../api/faqApi";
import moment from "moment";
import { toast } from "react-toastify";
import AlertPopup from "../../components/AlertPopup";

const ManageCatagory = () => {
  let navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { id, page } = params;
  const [searchParams, setSearchParams] = useSearchParams();

  //--삭제 state--//
  const [isOpen, setIsOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("삭제 하시겠습니까?");
  const [content, setContent] = useState("삭제된 데이터는 복구가 불가능합니다.");
  const [alertValue, setAlertValue] = useState(false);
  const [cancelTitle, setCancelTitle] = useState("취소");
  const [confirmTitle, setConfirmTitle] = useState("삭제");
  //--삭제 state--//

  const [catagoryData, setCatagoryData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(null);

  useEffect(() => {
    setSearchKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    setCurrentPage(page ? Number(page) : 1);
    getCategoryList();
  }, [currentPage, location]);

  const getCategoryList = async () => {
    try {
      const list = (
        await FaqApi.GetCategory({
          page: currentPage,
          size: 10,
          keyword: searchParams.get("keyword"),
        })
      ).data.data;
      setCatagoryData(list.content);
      setTotalCount(list.totalCount);
      setPostsPerPage(list.content.length);

      const count = Math.ceil(list.totalCount / list.content.length / 4);

      for (let i = 1; i <= count; i++) {
        if (currentPage / 4 <= i) {
          setStartIndex(4 * (i - 1));
          return;
        }
      }
    } catch (err) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  useEffect(() => {
    let page = Math.ceil(totalCount / postsPerPage);
    let arr = [];
    for (var i = 1; i <= page; i++) {
      arr.push(i);
    }
    setPageIndex(arr);
  }, [totalCount]);

  const activationHandler = async (id, state) => {
    let obj = {
      activation: state,
    };
    try {
      await FaqApi.postCategoryActivation(id, obj);
      toast("변경되었습니다.");
    } catch (err) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const [subMenuActive, setSubMenuActive] = useState(null);

  return (
    <div>
      <section>
        <span className="faq-title">카테고리 관리</span>
        <div className="manage-table-container">
          <div className="Manage-catagory-search-container">
            <div className="Manage-catagory-search-number">
              <span>전체 갯수</span>
              <span>{totalCount}</span>
            </div>
            <div className="Manage-catagory-search">
              <div style={{ position: "relative" }}>
                <input
                  className="table-search-input"
                  type="text"
                  placeholder="검색어를 입력하세요"
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                  }}
                  value={searchKeyword}
                />
                <img
                  src="/img/search.svg"
                  alt=""
                  style={{
                    position: "absolute",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/admin/faq/1?keyword=${searchKeyword}`, {
                      key: 1,
                      state: {
                        id: "카테고리 관리",
                      },
                    });
                  }}
                />
              </div>
              <div
                onClick={() => {
                  navigate("/admin/category/add");
                }}
                className="cursorPointer"
              >
                <img src="\img\sidebar-plus-icon.svg" />
                <span>추가하기</span>
              </div>
            </div>
          </div>
          <div className="Manage-table manage-table-catagory">
            <table>
              <thead>
                <tr>
                  <th>카테고리명</th>
                  <th>등록일자</th>
                  <th>상태</th>
                  <th>더보기</th>
                </tr>
              </thead>
              <tbody>
                {catagoryData.map((item, index) => {
                  let createDate = moment(item.createdAt).utc().format("YYYY/MM/DD");
                  return (
                    <tr key={index}>
                      <td>{item.title}</td>
                      <td>{createDate}</td>
                      <td className="table-sub-catagory-container">
                        <a
                          onClick={() => {
                            if (subMenuActive === index) setSubMenuActive(null);
                            else setSubMenuActive(index);
                          }}
                        >
                          <span>{item.activation ? "활성" : "비활성"}</span>
                          <img src="\img\table-togle.svg" />
                        </a>
                        {subMenuActive === index ? (
                          <ul className="table-sub-menu table-sub-menu-catagory">
                            <li
                              onClick={() => {
                                let copyData = [...catagoryData];
                                copyData[index].activation = true;
                                setCatagoryData(copyData);
                                setSubMenuActive(null);
                                activationHandler(item.id, true);
                              }}
                            >
                              활성
                            </li>
                            <li
                              onClick={() => {
                                let copyData = [...catagoryData];
                                copyData[index].activation = false;
                                setCatagoryData(copyData);
                                setSubMenuActive(null);
                                activationHandler(item.id, false);
                              }}
                            >
                              비활성
                            </li>
                          </ul>
                        ) : null}
                      </td>
                      <td>
                        <Link to={`/admin/category/edit/${item.id}`} state={{ id: item.id }}>
                          수정
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="manage-table-pagenation-container">
              <div className="manage-table-pagenation">
                <a
                  onClick={() => {
                    if (currentPage <= 1) {
                      return;
                    } else {
                      navigate(`/admin/faq/${currentPage - 1}?keyword=${searchKeyword}`, {
                        key: 1,
                        state: {
                          id: "카테고리 관리",
                        },
                      });
                    }
                  }}
                >
                  <img src="\img\chevron-left.svg" />
                </a>
                {pageIndex.slice(startIndex, startIndex + 4).map((item, index) => {
                  return (
                    <a
                      key={index}
                      className={currentPage === item ? "pagenation-focus" : null}
                      onClick={() => {
                        navigate(`/admin/faq/${item}?keyword=${searchKeyword}`, {
                          key: 1,
                          state: {
                            id: "카테고리 관리",
                          },
                        });
                      }}
                    >
                      {item}
                    </a>
                  );
                })}
                <a
                  onClick={() => {
                    if (currentPage >= pageIndex.length) {
                      return;
                    } else {
                      navigate(`/admin/faq/${currentPage + 1}?keyword=${searchKeyword}`, {
                        key: 1,
                        state: {
                          id: "카테고리 관리",
                        },
                      });
                    }
                  }}
                >
                  <img src="\img\chevron-right.svg" />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* 삭제 컴포넌트 */}
        <AlertPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          headerTitle={headerTitle}
          content={content}
          setAlertValue={setAlertValue}
          cancelTitle={cancelTitle}
          confirmTitle={confirmTitle}
        />
      </section>
    </div>
  );
};

export default ManageCatagory;
