import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import "../../css/gathering/ManageGathering.css";
import "../../css/Manage.css";
import moment from "moment";
import MeetingApi from "../../api/meetingApi";
import { toast } from "react-toastify";
import AlertPopup from "../../components/AlertPopup";
const ManageGathering = () => {
  let navigate = useNavigate();
  const { page } = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  //--삭제 state--//
  const [isOpen, setIsOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("삭제 하시겠습니까?");
  const [content, setContent] = useState("삭제된 데이터는 복구가 불가능합니다.");
  const [alertValue, setAlertValue] = useState(false);
  const [cancelTitle, setCancelTitle] = useState("취소");
  const [confirmTitle, setConfirmTitle] = useState("삭제");
  //--삭제 state--//

  // set data
  const [searchDto, setSearchDto] = useState({
    keyword: "",
    meetingStatus: "",
  });
  const [dataList, setDataList] = useState([]);

  // pagination
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(null);

  const [selectFlag, setSelectFlag] = useState(null);

  useEffect(() => {
    setSearchDto({
      keyword: searchParams.get("keyword") ? searchParams.get("keyword") : "",
      meetingStatus: searchParams.get("status") ? searchParams.get("status") : "",
    });
    setCurrentPage(Number(page));
    setData();
  }, [currentPage, location]);

  useMemo(() => {
    let page = Math.ceil(totalCount / postsPerPage);
    let arr = [];
    for (var i = 1; i <= page; i++) {
      arr.push(i);
    }
    setPageIndex(arr);
  }, [totalCount]);

  async function setData() {
    try {
      const response = await MeetingApi.getMeetings({
        page: currentPage,
        size: 10,
        keyword: searchParams.get("keyword"),
        meetingStatus: searchParams.get("status"),
        managerId: sessionStorage.getItem("managerAuthority") === "MANAGER" ? sessionStorage.getItem("id") : "",
      });
      const data = response.data.data;

      setDataList(
        data.content.map((item) => {
          return {
            ...item,
            meetingDateString: moment(new Date(item.meetingDate)).utc().format("yy/MM/DD"),
            recruitingStartDateString: moment(new Date(item.recruitingStartDate)).utc().format("yy/MM/DD"),
            recruitingEndDateString: moment(new Date(item.recruitingEndDate)).utc().format("yy/MM/DD"),
          };
        })
      );
      setTotalCount(data.totalCount);
      setPostsPerPage(data.content.length);

      const count = Math.ceil(data.totalCount / data.content.length / 4);

      for (let i = 1; i <= count; i++) {
        if (currentPage / 4 <= i) {
          setStartIndex(4 * (i - 1));
          return;
        }
      }
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  }

  async function setMeetingStatus(id, meetingStatus) {
    try {
      await MeetingApi.setStatus(id, { meetingStatus: meetingStatus });
      await setData();
      setSelectFlag(-1);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  }

  //삭제 실행하는 곳
  const delMeeting = async (id) => {
    setIsOpen(true);
    setDeleteId(id);
  };

  const [deleteId, setDeleteId] = useState(-1);
  useEffect(() => {
    const del = async () => {
      try {
        if (deleteId <= 0) {
          return;
        }
        await MeetingApi.delMeeting(deleteId);
        toast("삭제되었습니다.");
        setData();
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    };
    if (alertValue) {
      del();
    }
  }, [alertValue]);

  return (
    <div className="Manage-container">
      <section className="Manage-contents Manage-contents-table Manage-contents-table-gathering">
        <span>모임 관리</span>
        <div className="manage-table-container">
          <div className="Manage-gathering-filter">
            <a
              onClick={() => {
                // setSearchDto((searchDto) => ({
                //   ...searchDto,
                //   meetingStatus: "",
                // }));
                navigate(`/admin/gathering/1?keyword=${searchDto.keyword}&authority=`);
              }}
              className={searchDto.meetingStatus === "" ? "filter-color" : null}
            >
              전체
            </a>
            <a
              onClick={() => {
                // setSearchDto((searchDto) => ({
                //   ...searchDto,
                //   meetingStatus: "RECRUITING",
                // }));
                navigate(`/admin/gathering/1?keyword=${searchDto.keyword}&authority=RECRUITING`);
              }}
              className={searchDto.meetingStatus === "RECRUITING" ? "filter-color" : null}
            >
              모집중
            </a>
            <a
              onClick={() => {
                // setSearchDto((searchDto) => ({
                //   ...searchDto,
                //   meetingStatus: "COMPLETE",
                // }));
                navigate(`/admin/gathering/1?keyword=${searchDto.keyword}&authority=COMPLETE`);
              }}
              className={searchDto.meetingStatus === "COMPLETE" ? "filter-color" : null}
            >
              모집완료 (진행중)
            </a>
            <a
              onClick={() => {
                // setSearchDto((searchDto) => ({
                //   ...searchDto,
                //   meetingStatus: "CANCEL",
                // }));
                navigate(`/admin/gathering/1?keyword=${searchDto.keyword}&authority=CANCEL`);
              }}
              className={searchDto.meetingStatus === "CANCEL" ? "filter-color" : null}
            >
              취소
            </a>
            <a
              onClick={() => {
                setSearchDto((searchDto) => ({
                  ...searchDto,
                  meetingStatus: "END",
                }));
              }}
              className={searchDto.meetingStatus === "END" ? "filter-color" : null}
            >
              종료
            </a>
          </div>
          <div className="Manage-gathering-search-container">
            <div className="Manage-gathering-search-number">
              <span>전체 갯수</span>
              <span>{totalCount}</span>
            </div>
            <div className="Manage-gathering-search">
              <div style={{ position: "relative" }}>
                <input
                  className="table-search-input"
                  type="text"
                  placeholder="검색어를 입력하세요"
                  onChange={(e) => {
                    setSearchDto((searchDto) => ({
                      ...searchDto,
                      keyword: e.target.value,
                    }));
                  }}
                  value={searchDto.keyword}
                />
                <img
                  src="/img/search.svg"
                  alt=""
                  style={{
                    position: "absolute",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/admin/gathering/1?keyword=${searchDto.keyword}&authority=${searchDto.meetingStatus}`);
                  }}
                />
              </div>
              <div onClick={() => navigate("/admin/gathering/add")} style={{ cursor: "pointer" }}>
                <img src="\img\sidebar-plus-icon.svg" />
                <span>모임 추가</span>
              </div>
            </div>
          </div>
          <div className="Manage-table manage-table-gathering">
            <table>
              <thead>
                <tr>
                  <th>상태</th>
                  <th>모임 제목</th>
                  <th>모임시작일</th>
                  <th>모집기간</th>
                  <th>상호명</th>
                  <th>담당자 휴대전화</th>
                  <th>삭제</th>
                  <th>상세보기</th>
                </tr>
              </thead>
              <tbody>
                {dataList.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="table-sub-catagory-container">
                        <a
                          onClick={() => {
                            if (selectFlag === index) setSelectFlag(null);
                            else setSelectFlag(index);
                          }}
                        >
                          <span className={item.meetingStatusTitle === "취소" ? "toggled-text-red" : null}>
                            {item.meetingStatusTitle}
                          </span>
                          <img src="/img/table-togle.svg" />
                        </a>
                        {selectFlag === index ? (
                          <ul className="table-sub-menu table-sub-menu-gathering">
                            <li
                              onClick={() => {
                                setMeetingStatus(item.id, "END");
                              }}
                            >
                              종료
                            </li>
                            <li
                              onClick={() => {
                                setMeetingStatus(item.id, "RECRUITING");
                              }}
                            >
                              모집중
                            </li>
                            <li
                              onClick={() => {
                                setMeetingStatus(item.id, "COMPLETE");
                              }}
                            >
                              모집완료
                            </li>
                            <li
                              onClick={() => {
                                setMeetingStatus(item.id, "CANCEL");
                              }}
                            >
                              취소
                            </li>
                          </ul>
                        ) : null}
                      </td>
                      <td>
                        <span>{item.title}</span>
                      </td>
                      <td>{item.meetingDateString}</td>
                      <td>
                        {item.recruitingStartDateString}
                        <br />~ {item.recruitingEndDateString}
                      </td>
                      <td>
                        <span>{item.space.companyName}</span>
                      </td>
                      <td>{item.space.phoneNumber}</td>
                      <td>
                        <a onClick={() => delMeeting(item.id)}>삭제</a>
                      </td>
                      <td>
                        <Link to={`/admin/gathering/detail/${item.id}`} state={{ id: item.id }}>
                          <img src="\img\see-more-btn-plus.svg" />
                          <span>보기</span>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="manage-table-pagenation-container">
              <div className="manage-table-pagenation">
                <a
                  onClick={() => {
                    if (currentPage <= 1) {
                      return;
                    } else {
                      navigate(
                        `/admin/gathering/${currentPage - 1}?keyword=${searchDto.keyword}&status=${
                          searchDto.meetingStatus
                        }`
                      );
                    }
                  }}
                >
                  <img src="\img\chevron-left.svg" />
                </a>
                {pageIndex.slice(startIndex, startIndex + 4).map((item, index) => {
                  return (
                    <a
                      key={index}
                      className={currentPage === item ? "pagenation-focus" : null}
                      onClick={() => {
                        navigate(
                          `/admin/gathering/${item}?keyword=${searchDto.keyword}&status=${searchDto.meetingStatus}`
                        );
                      }}
                    >
                      {item}
                    </a>
                  );
                })}
                <a
                  onClick={() => {
                    if (currentPage >= pageIndex.length) {
                      return;
                    } else {
                      navigate(
                        `/admin/gathering/${currentPage + 1}?keyword=${searchDto.keyword}&status=${
                          searchDto.meetingStatus
                        }`
                      );
                    }
                  }}
                >
                  <img src="\img\chevron-right.svg" />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* 삭제 컴포넌트 */}
        <AlertPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          headerTitle={headerTitle}
          content={content}
          setAlertValue={setAlertValue}
          cancelTitle={cancelTitle}
          confirmTitle={confirmTitle}
        />
      </section>
    </div>
  );
};

export default ManageGathering;
