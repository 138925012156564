import React, { useState } from "react";
import "../../css/Manage.css";
import "../../css/gathering/ManageGatheringProgramAdd.css";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { nullCheck } from "../../utils/check";
import MeetingApi from "../../api/meetingApi";

const ManageGatheringProgramAdd = (props) => {

  const programCateogryItems = [
    { value: 'ACTIVITY', title: '액티비티' },
    { value: 'CULTURE', title: '문화·예술' },
    { value: 'COOK', title: '요리·DIY' },
    { value: 'SELF', title: '자기계발' },
    { value: 'HOBBY', title: '취미활동' },
    { value: 'HEALING', title: '힐링' },
    { value: 'ETC', title: '기타' }]

  const [managerId, setManagerId] = useState(0)
  const [spaceId, setSpaceId] = useState(0)
  const [meetingId, setMeetingId] = useState(0)
  const [programCategory, setProgramCategory] = useState('')
  const [programCategoryTitle, setProgramCategoryTitle] = useState('프로그램 카테고리를 선택해주세요.')
  const [programCategoryFlag, setProgramCategoryFlag] = useState(false)
  const [title, setTitle] = useState('')
  const [requiredTime, setRequiredTime] = useState('')
  const [content, setContent] = useState('')
  const [images, setImages] = useState([])

  // mounted
  useEffect(() => {
    setManagerId(sessionStorage.getItem('id'))
  }, [])

  useEffect(() => {
    setSpaceId(props.spaceId)
  }, [props.spaceId])

  useEffect(() => {
    setMeetingId(props.meetingId)
  }, [props.meetingId])

  // 파일 업로드
  const inputFiles = (e) => {
    if (images.length === 5) {
      toast("이미지는 최대 5장까지 등록가능합니다.");
      return
    }

    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = e => {
        const fileTemp = {
          imageOriginFileName: file.name,
          src: e.target.result,
          file: file
        }
        setImages([...images, fileTemp])
      }
    }
    e.target.value = ''
  };
  const deleteFiles = (index) => {
    setImages(images.filter((image, currentIndex) => currentIndex !== index))
  };

  // 저장
  const save = async () => {
    try {
      if (nullCheck(programCategory)) {
        toast("프로그램 카테고리를 선택해주세요.");
        return
      }
      if (nullCheck(title)) {
        toast("프로그램명을 입력해주세요.");
        return
      }
      if (nullCheck(requiredTime)) {
        toast("소요시간을 입력해주세요.");
        return
      }
      if (nullCheck(content)) {
        toast("상세설명을 입력해주세요.");
        return
      }

      const requestDto = new FormData()
      requestDto.append('managerId', managerId)
      requestDto.append('spaceId', spaceId)
      if (!nullCheck(meetingId)) {
        requestDto.append('meetingId', meetingId)
      }
      requestDto.append('programCategory', programCategory)
      requestDto.append('title', title)
      requestDto.append('requiredTime', requiredTime)
      requestDto.append('content', content)

      if (!nullCheck(images)) {
        for (let index = 0; index < images.length; index++) {
          const element = images[index];
          requestDto.append('images', element.file)
        }
      }

      await MeetingApi.postProgram(requestDto)
      toast('저장되었습니다.')
      props.getProgramList()
      setManagerId(managerId)
      setSpaceId(spaceId)
      setProgramCategory('')
      setProgramCategoryTitle('프로그램 카테고리를 선택해주세요.')
      setProgramCategoryFlag(false)
      setTitle('')
      setRequiredTime('')
      setContent('')
      setImages([])
      props.setAddProgramPopupFlag(false)
    } catch (error) {
      toast('서버에 문제가있습니다. 잠시후 다시 시도해주세요.')
    }
  }

  return (
    props.openFlag ?
      <div>
        <div className="gathering-program-add-container">
          <div className="gathering-program-add-popup" ref={props.searchRef}>
            <span>새로운 프로그램 추가하기</span>
            <div className="Manage-input-container manage-input-container-gathering-popup">
              <p className="Manage-input-title">프로그램 카테고리*</p>
              <div className="select">
                <div
                  className="selected"
                  onClick={() => {
                    setProgramCategoryFlag(!programCategoryFlag);
                  }}
                >
                  <div
                    className={
                      programCategory === '' ? "selected-value-basic" : "selected-value"
                    }
                  >
                    {programCategoryTitle}
                  </div>
                  <div className="select-toggle-btn">
                    <img src="\img\table-togle.svg" />
                  </div>
                </div>
                {programCategoryFlag ? (
                  <ul className="option-container">
                    {programCateogryItems.map((programCategory, index) => (
                      <li
                        key={index}
                        className="option"
                        onClick={() => {
                          setProgramCategory(programCategory.value)
                          setProgramCategoryTitle(programCategory.title)
                          setProgramCategoryFlag(!programCategoryFlag);
                        }}
                      >
                        {programCategory.title}
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
            </div>
            <div className="Manage-input-container manage-input-container-gathering-popup">
              <p className="Manage-input-title">프로그램명*</p>
              <input
                type="text"
                className="Manage-input-textarea"
                placeholder="프로그램명"
                style={{ width: '366px' }}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value)
                }}
              />
            </div>
            <div className="Manage-input-container manage-input-container-gathering-popup">
              <p className="Manage-input-title">소요시간*</p>
              <input type="number" className="Manage-input-textarea" style={{ width: '366px' }} placeholder="N시간" value={requiredTime}
                onChange={(e) => {
                  setRequiredTime(e.target.value)
                }}
              />
            </div>
            <div className="Manage-input-container manage-input-container-popup">
              <p className="Manage-input-title">상세설명*</p>
              <textarea
                className="Manage-input-textarea-popup"
                placeholder="상세설명을 입력하세요"
                value={content}
                onChange={(e) => {
                  setContent(e.target.value)
                }}
              ></textarea>
            </div>
            <div className="Manage-input-container manage-input-container-popup">
              <p className="Manage-input-title">이미지(*최대5장)</p>
              <div className="gathering-popup-img-container">
                <label htmlFor="img-input2">
                  <img src="\img\file-upload-plus.svg" />
                  <span>파일 등록</span>
                </label>
                <input
                  id="img-input2"
                  className="Manage-input-textarea manage-form-gathering-add-input"
                  type="file"
                  required
                  onChange={(e) => {
                    inputFiles(e)
                  }}
                />
                <div className="gathering-popup-img-list">
                  <div className="gathering-popup-img-list-up">

                    {images.map((image, index) => (
                      <div key={index} className="gathering-popup-img-name">
                        <span>{image.imageOriginFileName}</span>
                        <img src="\img\image-cancel.svg" onClick={(e) => {
                          deleteFiles(index)
                        }} />
                      </div>
                    ))}

                  </div>
                </div>
              </div>
            </div>
            <div className="save-gathering-program-add-popup">
              <a
                onClick={(e) => {
                  setManagerId(0)
                  setSpaceId(0)
                  setProgramCategory('')
                  setProgramCategoryTitle('프로그램 카테고리를 선택해주세요.')
                  setProgramCategoryFlag(false)
                  setTitle('')
                  setRequiredTime('')
                  setContent('')
                  setImages([])

                  props.setAddProgramPopupFlag(false)
                }}
              >닫기</a>
              <a
                onClick={(e) => {
                  save()
                }}
              >추가하기</a>
            </div>
          </div>
        </div>
      </div> : null
  );
};

export default ManageGatheringProgramAdd;
