import React, { useEffect, useState } from "react";
import "../../css/gathering/ManageGatheringCommentPopup.css";
import "../../css/Manage.css";
import CommunityApi from "../../api/communityApi";
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import MeetingNoticeApi from "../../api/meetingNoticeApi";

const ManageGatheringCommentPopup = (props) => {
  const [communityReview, setCommunityReview] = useState({
    date: null,
    nickname: null,
    email: null,
    state: null,
    content: null,
  });
  const [reviewId, setReviewId] = useState(null);

  useEffect(() => {
    let id = props.clickId;
    setReviewId(id);
    getReviewInfo(id);
  }, []);

  const getReviewInfo = async (id) => {
    try {
      const response = (await CommunityApi.GetCommunityReviewInfo(id)).data.data
        .content;
      let createDate = moment(response.createdAt).utc().format("YYYY/MM/DD HH:mm:ss");
      let category =
        response.id === communityReview.parentId ? "댓글" : "대댓글";
      setCommunityReview({
        date: createDate,
        nickname: response.member.nickname,
        email: response.member.email,
        state: category,
        content: response.content,
        activation: response.activation,
      });      
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const reviewActivation = async () => {
    let obj = {
      activation: false,
    };
    try {
      await CommunityApi.reviewActivation(reviewId, obj);
      toast("변경되었습니다.");
      props.setPopup(false);      
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div>
      <div className="Popup-container">
        <div className="Popup" ref={props.searchRef}>
          <div className="Popup-title gathering-comment-popup-title">
            <span>댓글 상세</span>
            {communityReview.activation ? <Link onClick={reviewActivation}>숨김</Link> : null}
          </div>
          <div className="gathering-comment-popup-middle">
            <div className="Manage-form-view-details">
              <span>게시일자</span>
              <span>{communityReview.date}</span>
            </div>
            <div className="Manage-form-view-details">
              <span>작성자 별명</span>
              <span>{communityReview.nickname}</span>
            </div>
            <div className="Manage-form-view-details">
              <span>작성자 이메일</span>
              <span>{communityReview.email}</span>
            </div>
            <div className="Manage-form-view-details">
              <span>분류</span>
              <span>{communityReview.state}</span>
            </div>
            <div className="Manage-form-view-details Manage-form-view-details-textarea">
              <span>내용</span>
              <span>{communityReview.content}</span>
            </div>
          </div>
          <div
            style={{
              cursor: 'pointer'
            }}
            className="gathering-comment-popup-close"
            onClick={() => {
              props.setPopup(false);
            }}
          >
            <span>닫기</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageGatheringCommentPopup;
