import api from "./api";

export default class SpaceReviewApi {
  static async Get(data) {
    return await api.get(`space-review`, data);
  }

  static async GetReview(id) {
    return await api.get(`space-review/${id}`);
  }

  static async PostActivation(id, data) {
    return await api.post(`space-review/activation/${id}`, data);
  }
}
