import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../../css/gathering/ManageGatheringNotice.css";
import "../../css/Manage.css";
import MeetingNoticeApi from "../../api/meetingNoticeApi";
import moment from "moment";
import { toast } from "react-toastify";
const ManageGatheringNotice = (props) => {
  let navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { id, page } = params;

  const [noticeList, setNoticeList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(null);

  useEffect(() => {
    setCurrentPage(page ? Number(page) : 1);
    getNoticeList();
  }, [currentPage, location]);

  const getNoticeList = async () => {
    try {
      const list = (
        await MeetingNoticeApi.Get({
          page: currentPage,
          size: 10,
          meetingId: props.meetingId,
        })
      ).data.data;
      setNoticeList((noticeList) => list.content);
      setTotalCount(list.totalCount);
      setPostsPerPage(list.content.length);

      const count = Math.ceil(list.totalCount / list.content.length / 4);

      for (let i = 1; i <= count; i++) {
        if (currentPage / 4 <= i) {
          setStartIndex(4 * (i - 1));
          console.log();
          return;
        }
      }
    } catch (err) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  useEffect(() => {
    let page = Math.ceil(totalCount / postsPerPage);
    let arr = [];
    for (var i = 1; i <= page; i++) {
      arr.push(i);
    }
    setPageIndex(arr);
  }, [totalCount]);

  return (
    <div>
      <section>
        <div className=" manage-form-gathering-notice">
          <div className="gathering-notice-add-container">
            <div className="gathering-notice-number">
              <span>공지사항 갯수</span>
              <span>{totalCount}</span>
            </div>
            <div className="gathering-notice-add-btn">
              <Link to={`/admin/gathering/detail/notice/add/${params.id}`}>
                <img src="\img\plus.svg" />
                <span>추가하기</span>
              </Link>
            </div>
          </div>
          <div className="gather-container">
            <table className="gathering-notice-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>제목</th>
                  <th>게시 관리자 이메일</th>
                  <th>게시일자</th>
                  <th>댓글수</th>
                  <th>더보기</th>
                </tr>
              </thead>
              <tbody>
                {noticeList.map((item, index) => {
                  let createDate = moment(item.createdAt).utc().format("YYYY/MM/DD");
                  return (
                    <tr key={index}>
                      <td>{totalCount - (index + (currentPage - 1) * 10)}</td>
                      <td>
                        <span>{item.title}</span>
                      </td>
                      <td>{item.manager.email}</td>
                      <td>{createDate}</td>
                      <td>{item.reviewCount}</td>
                      <td>
                        <Link to={`/admin/gathering/detail/notice/detail/${item.id}`} state={{ id: item.id }}>
                          <img src="\img\see-more-btn-plus.svg" />
                          <span>보기</span>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="manage-table-pagenation-container">
              <div className="manage-table-pagenation">
                <a
                  onClick={() => {
                    if (currentPage <= 1) {
                      return;
                    } else {
                      navigate(`/admin/gathering/detail/${id}/${currentPage - 1}`, {
                        key: 2,
                        state: {
                          id: "공지",
                        },
                      });
                    }
                  }}
                >
                  <img src="\img\chevron-left.svg" />
                </a>
                {pageIndex.slice(startIndex, startIndex + 4).map((item, index) => {
                  return (
                    <a
                      key={index}
                      className={currentPage === item ? "pagenation-focus" : null}
                      onClick={() => {
                        navigate(`/admin/gathering/detail/${id}/${item}`, {
                          key: 2,
                          state: {
                            id: "공지",
                          },
                        });
                      }}
                    >
                      {item}
                    </a>
                  );
                })}
                <a
                  onClick={() => {
                    if (currentPage >= pageIndex.length) {
                      return;
                    } else {
                      navigate(`/admin/gathering/detail/${id}/${currentPage + 1}`, {
                        key: 2,
                        state: {
                          id: "공지",
                        },
                      });
                    }
                  }}
                >
                  <img src="\img\chevron-right.svg" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageGatheringNotice;
