import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../../css/faq/ManageFaqSeeMore.css";
import "../../css/Manage.css";
import FaqApi from "../../api/faqApi";
import moment from "moment";
import { toast } from "react-toastify";
import AlertPopup from "../../components/AlertPopup";

const ManageFaqSeeMore = () => {
  let navigate = useNavigate();
  let location = useLocation();

  //--삭제 state--//
  const [isOpen, setIsOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("삭제 하시겠습니까?");
  const [content, setContent] = useState("삭제된 데이터는 복구가 불가능합니다.");
  const [alertValue, setAlertValue] = useState(false);
  const [cancelTitle, setCancelTitle] = useState("취소");
  const [confirmTitle, setConfirmTitle] = useState("삭제");
  //--삭제 state--//

  const [FaqId, setFaqId] = useState(null);
  const [faqInfo, setFaqInfo] = useState({
    category: null,
    title: null,
    date: null,
    email: null,
    content: null,
  });

  useEffect(() => {
    try {
      if (location.state) {
        const { id } = location.state;
        setFaqId(location.state.id);
        getFaqInfo(id);
      }
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  }, [location]);

  const getFaqInfo = async (id) => {
    try {
      const info = (await FaqApi.Get(id)).data.data.content;
      let createDate = moment(info.createdAt).utc().format("YYYY/MM/DD MM:MM:MM");
      setFaqInfo({
        category: info.faqCategory.title,
        title: info.title,
        date: createDate,
        email: info.manager.email,
        content: info.content,
      });
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  //삭제 실행하는 곳
  const delFaq = async () => {
    setIsOpen(true);
    setDeleteId(FaqId);
  };

  const [deleteId, setDeleteId] = useState(-1);
  useEffect(() => {
    const del = async () => {
      try {
        if (deleteId <= 0) {
          return;
        }
        await FaqApi.Del(deleteId);
        toast("삭제되었습니다.");
        navigate("/admin/faq/1");
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    };
    if (alertValue) {
      del();
    }
  }, [alertValue]);

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-faq-seemore">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
          <div>
            <a onClick={delFaq}>삭제</a>
            <Link to={`/admin/faq/detail/edit/${FaqId}`} state={{ id: FaqId }}>
              수정하기
            </Link>
          </div>
        </div>
        <div className="Manage-form manage-form-faq-see-more">
          <span>상세보기</span>
          <div className="Manage-form-view-details">
            <span>카테고리</span>
            <span>{faqInfo.category}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>제목</span>
            <span>{faqInfo.title}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>게시일자</span>
            <span>{faqInfo.date}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>게시 관리자 이메일</span>
            <span>{faqInfo.email}</span>
          </div>
          <div className="Manage-form-view-details manage-flex-start">
            <span>본문</span>
            <span>{faqInfo.content}</span>
          </div>
        </div>
        {/* 삭제 컴포넌트 */}
        <AlertPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          headerTitle={headerTitle}
          content={content}
          setAlertValue={setAlertValue}
          cancelTitle={cancelTitle}
          confirmTitle={confirmTitle}
        />
      </section>
    </div>
  );
};

export default ManageFaqSeeMore;
