import React, { useState, useEffect } from "react";
import "../../css/terms/ManageTerms.css";
import "../../css/Manage.css";
import TermsApi from "../../api/termsApi";
import { toast } from "react-toastify";

const ManageTerms = () => {
  const [termsOpen, setTermsOpen] = useState(null);
  const [termsList, setTermsList] = useState([]);

  useEffect(() => {
    const getProgramList = async () => {
      try {
        const list = (await TermsApi.Get()).data.data;
        setTermsList(list.content);
      } catch (err) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    };
    getProgramList();
  }, []);

  const termsHandler = async (itemId, index) => {
    try {
      await TermsApi.Patch(itemId, termsList[index]);
      toast("저장되었습니다.");
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <span>약관 수정하기</span>
        {termsList &&
          termsList.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  "Manage-form" +
                  " manage-form-terms" +
                  (termsOpen === item.id ? "" : " manage-form-terms-close")
                }
              >
                <div
                  onClick={() => {
                    if (termsOpen === item.id) {
                      setTermsOpen(null);
                    } else setTermsOpen(item.id);
                  }}
                >
                  <span>{item.title}</span>
                  <a>
                    {termsOpen !== item.id ? (
                      <img src="\img\table-togle.svg" />
                    ) : (
                      <img src="\img\table-toggle-up.svg" />
                    )}
                  </a>
                </div>
                {termsOpen !== item.id ? null : (
                  <>
                    <div className="Manage-form-textarea-wrap">
                      <textarea
                        className="Manage-form-textarea"
                        value={item.content}
                        onChange={(e) => {
                          let copyData = [...termsList];
                          copyData[index].content = e.target.value;
                          setTermsList(copyData);
                        }}
                      ></textarea>
                    </div>
                    <div className="Manage-form-save Manage-form-save-terms cursorPointer">
                      <button
                        onClick={() => {
                          termsHandler(item.id, index);
                        }}
                      >
                        저장하기
                      </button>
                    </div>
                  </>
                )}
              </div>
            );
          })}
      </section>
    </div>
  );
};

export default ManageTerms;
