import React, { useState, useEffect } from "react";
import "../../css/permission/ManagePermissionsAdit.css";
import "../../css/permission/ManagePermissionsInformation.css";
import "../../css/Manage.css";
import ManagerApi from "../../api/managerApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ManagePermissionsInformation = () => {
  let navigate = useNavigate();

  const [baseData, setBaseData] = useState({});

  useEffect(() => {
    getBaseData();
  }, []);

  const getBaseData = async () => {
    try {
      const baseInfo = (await ManagerApi.GetBase()).data.data.content;
      setBaseData(baseInfo);
    } catch (err) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const baseDataHandler = async () => {
    try {
      await ManagerApi.PatchBase(baseData);
      toast("저장되었습니다.");
      navigate(-1)
    } catch (err) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div className="Manage-container">
      <div>
        <section className="Manage-contents">
          <span>기본 정보 관리</span>
          <div className="Manage-form manage-form-information">
            <p>기업 정보</p>
            <div className="Manage-input-container">
              <p className="Manage-input-title">대표이사</p>
              <input
                type="text"
                id="ceo"
                className="Manage-input-textarea"
                value={baseData.ceo}
                onChange={(e) => {
                  setBaseData({
                    ...baseData,
                    ceo: e.target.value,
                  });
                }}
              />
            </div>
            <div className="Manage-input-container">
              <p className="Manage-input-title">주소</p>
              <input
                type="text"
                id="address"
                className="Manage-input-textarea"
                value={baseData.address}
                onChange={(e) => {
                  setBaseData({
                    ...baseData,
                    address: e.target.value,
                  });
                }}
              />
            </div>
            <div className="Manage-input-container">
              <p className="Manage-input-title">대표전화</p>
              <input
                type="text"
                id="tell"
                className="Manage-input-textarea"
                value={baseData.tell}
                onChange={(e) => {
                  setBaseData({
                    ...baseData,
                    tell: e.target.value,
                  });
                }}
              />
            </div>
            <div className="Manage-input-container">
              <p className="Manage-input-title">이메일</p>
              <input
                type="email"
                id="email"
                className="Manage-input-textarea"
                value={baseData.email}
                onChange={(e) => {
                  setBaseData({
                    ...baseData,
                    email: e.target.value,
                  });
                }}
              />
            </div>
            <div className="Manage-input-container">
              <p className="Manage-input-title">고객센터</p>
              <input
                type="tel"
                id="centerTell"
                className="Manage-input-textarea"
                value={baseData.centerTell}
                onChange={(e) => {
                  setBaseData({
                    ...baseData,
                    tell: e.target.value,
                  });
                }}
              />
            </div>
            <div className="Manage-input-container">
              <p className="Manage-input-title">사업자등록번호</p>
              <input
                type="text"
                id="businessNumber"
                className="Manage-input-textarea"
                value={baseData.businessNumber}
                onChange={(e) => {
                  setBaseData({
                    ...baseData,
                    businessNumber: e.target.value,
                  });
                }}
              />
            </div>
            <div className="Manage-input-container">
              <p className="Manage-input-title">통신판매신고번호</p>
              <input
                type="text"
                id="saleReportNumber"
                className="Manage-input-textarea"
                value={baseData.saleReportNumber}
                onChange={(e) => {
                  setBaseData({
                    ...baseData,
                    saleReportNumber: e.target.value,
                  });
                }}
              />
            </div>
            <div className="Manage-input-container">
              <p className="Manage-input-title">채널톡 URL</p>
              <input
                type="text"
                id="saleReportNumber"
                className="Manage-input-textarea"
                value={baseData.talkUrl}
                onChange={(e) => {
                  setBaseData({
                    ...baseData,
                    talkUrl: e.target.value,
                  });
                }}
              />
            </div>
            <div className="Manage-form-save manage-form-save-information">
              <button className="cursorPointer" onClick={baseDataHandler}>
                저장하기
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default ManagePermissionsInformation;
