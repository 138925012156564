import { Link, useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../../css/faq/ManageFaq.css";
import "../../css/Manage.css";
import FaqApi from "../../api/faqApi";
import moment from "moment";
import { toast } from "react-toastify";
import AlertPopup from "../../components/AlertPopup";
import { nullCheck } from "../../utils/check";

const ManageFaqDetail = () => {
  let navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { id, page } = params;
  const [searchParams, setSearchParams] = useSearchParams();

  //--삭제 state--//
  const [isOpen, setIsOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("삭제 하시겠습니까?");
  const [content, setContent] = useState("삭제된 데이터는 복구가 불가능합니다.");
  const [alertValue, setAlertValue] = useState(false);
  const [cancelTitle, setCancelTitle] = useState("취소");
  const [confirmTitle, setConfirmTitle] = useState("삭제");
  //--삭제 state--//

  const [faqCatagory, setFaqCategory] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [faqData, setFaqData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(null);

  useEffect(() => {
    setSearchKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    setCurrentCategory(searchParams.get("faqCategoryId") ? Number(searchParams.get("faqCategoryId")) : "");
    setCurrentPage(page ? Number(page) : 1);
    getFaqList();
  }, [currentPage, location]);

  const getFaqList = async () => {
    try {
      const list = (
        await FaqApi.GetList({
          page: currentPage,
          size: 10,
          keyword: searchParams.get("keyword"),
          faqCategoryId: nullCheck(searchParams.get("faqCategoryId")) ? "" : searchParams.get("faqCategoryId"),
        })
      ).data.data;
      const category = (await FaqApi.GetCategory()).data.data.content;
      setFaqData(list.content);
      setTotalCount(list.totalCount);

      // const arr = [];
      // category.map((item) => {
      //   arr.unshift(item);
      // });
      setFaqCategory(category);

      setPostsPerPage(list.content.length);

      const count = Math.ceil(list.totalCount / list.content.length / 5);

      for (let i = 1; i <= count; i++) {
        if (currentPage / 5 <= i) {
          setStartIndex(5 * (i - 1));
          return;
        }
      }
    } catch (err) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  useEffect(() => {
    let page = Math.ceil(totalCount / postsPerPage);
    let arr = [];
    for (var i = 1; i <= page; i++) {
      arr.push(i);
    }
    setPageIndex(arr);
  }, [totalCount]);

  const faqActivation = async (id, index, state) => {
    let copyData = [...faqData];
    copyData[index].activation = state;
    setFaqData(copyData);
    let arr = {
      activation: state,
    };
    try {
      await FaqApi.PostFaqActivation(id, arr);
      toast("변경되었습니다");
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  //삭제 실행하는 곳
  const delFaq = async (id) => {
    setIsOpen(true);
    setDeleteId(id);
  };

  const [deleteId, setDeleteId] = useState(-1);
  useEffect(() => {
    const del = async () => {
      try {
        if (deleteId <= 0) {
          return;
        }
        await FaqApi.Del(deleteId);
        toast("삭제되었습니다.");
        getFaqList();
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    };
    if (alertValue) {
      del();
    }
  }, [alertValue]);

  // const faqFilter = async (id) => {
  //   try {
  //     if (!id) {
  //       setCurrentCategory(0);
  //     } else {
  //       setCurrentCategory(id);
  //     }
  //     const filter = (await FaqApi.GetList({ faqCategoryId: id })).data.data;
  //     setPostsPerPage(filter.content.length);
  //     setFaqData(filter.content);
  //     setTotalCount(() => filter.totalCount);
  //   } catch (error) {
  //     toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
  //   }
  // };

  const [subMenuActive, setSubMenuActive] = useState(null);

  return (
    <div>
      <span className="faq-title">FAQ 관리</span>
      <div className="manage-table-container">
        <div className="Manage-faq-type">
          <a
            className={currentCategory === "" ? "filter-btn-active" : null}
            onClick={() => {
              navigate(`/admin/faq/1?keyword=${searchKeyword}&faqCategoryId=`, {
                key: 0,
                state: {
                  id: "faq 관리",
                },
              });
            }}
          >
            전체
          </a>
          {faqCatagory.map((item) => {
            return (
              <a
                className={currentCategory === item.id ? "filter-btn-active" : null}
                onClick={() => {
                  navigate(`/admin/faq/1?keyword=${searchKeyword}&faqCategoryId=${item.id}`, {
                    key: 0,
                    state: {
                      id: "faq 관리",
                    },
                  });
                }}
              >
                {item.title}
              </a>
            );
          })}
        </div>
        <div className="Manage-faq-search-container">
          <div className="Manage-faq-search-number">
            <span>전체 갯수</span>
            <span>{totalCount}</span>
          </div>
          <div className="Manage-faq-search">
            <div style={{ position: "relative" }}>
              <input
                className="table-search-input"
                type="text"
                placeholder="검색어를 입력하세요"
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                }}
                value={searchKeyword}
              />
              <img
                src="/img/search.svg"
                alt=""
                style={{
                  position: "absolute",
                  right: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/admin/faq/1?keyword=${searchKeyword}&faqCategoryId=${currentCategory}`, {
                    key: 0,
                    state: {
                      id: "faq 관리",
                    },
                  });
                }}
              />
            </div>
            <div onClick={() => navigate("/admin/faq/add")}>
              <img src="\img\sidebar-plus-icon.svg" />
              <span>추가하기</span>
            </div>
          </div>
        </div>
        <div className="Manage-table manage-table-faq">
          <table>
            <thead>
              <tr>
                <th>카테고리</th>
                <th>제목</th>
                <th>등록일자</th>
                <th>상태</th>
                <th>삭제</th>
                <th>더보기</th>
              </tr>
            </thead>
            <tbody>
              {faqData.map((item, index) => {
                let createDate = moment(item.createdAt).utc().format("YYYY/MM/DD");
                return (
                  <tr key={index}>
                    <td>{item.faqCategory.title}</td>
                    <td>{item.title}</td>
                    <td>{createDate}</td>
                    <td className="table-sub-catagory-container">
                      <a
                        onClick={() => {
                          if (subMenuActive === index) setSubMenuActive(null);
                          else setSubMenuActive(index);
                        }}
                      >
                        <span>{item.activation ? "활성" : "비활성"}</span>
                        <img src="\img\table-togle.svg" />
                      </a>
                      {subMenuActive === index ? (
                        <ul className="table-sub-menu table-sub-menu-faq">
                          <li
                            onClick={() => {
                              faqActivation(item.id, index, true);
                              setSubMenuActive(null);
                            }}
                          >
                            활성
                          </li>
                          <li
                            onClick={() => {
                              faqActivation(item.id, index, false);
                              setSubMenuActive(null);
                            }}
                          >
                            비활성
                          </li>
                        </ul>
                      ) : null}
                    </td>
                    <td>
                      <a onClick={() => delFaq(item.id)}>삭제</a>
                    </td>
                    <td>
                      <Link to={`/admin/faq/detail/${item.id}`} state={{ id: item.id }}>
                        <img src="\img\see-more-btn-plus.svg" />
                        <span>보기</span>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="manage-table-pagenation-container">
            <div className="manage-table-pagenation">
              <a
                onClick={() => {
                  if (currentPage <= 1) {
                    return;
                  } else {
                    navigate(
                      `/admin/faq/${currentPage - 1}?keyword=${searchKeyword}&faqCategoryId=${currentCategory}`,
                      {
                        key: 0,
                        state: {
                          id: "faq 관리",
                        },
                      }
                    );
                  }
                }}
              >
                <img src="\img\chevron-left.svg" />
              </a>
              {pageIndex.slice(startIndex, startIndex + 5).map((item, index) => {
                return (
                  <a
                    key={index}
                    className={currentPage === item ? "pagenation-focus" : null}
                    onClick={() => {
                      navigate(`/admin/faq/${item}?keyword=${searchKeyword}&faqCategoryId=${currentCategory}`, {
                        key: 0,
                        state: {
                          id: "faq 관리",
                        },
                      });
                    }}
                  >
                    {item}
                  </a>
                );
              })}
              <a
                onClick={() => {
                  if (currentPage >= pageIndex.length) {
                    return;
                  } else {
                    navigate(
                      `/admin/faq/${currentPage + 1}?keyword=${searchKeyword}&faqCategoryId=${currentCategory}`,
                      {
                        key: 0,
                        state: {
                          id: "faq 관리",
                        },
                      }
                    );
                  }
                }}
              >
                <img src="\img\chevron-right.svg" />
              </a>
            </div>
          </div>
        </div>
        {/* 삭제 컴포넌트 */}
        <AlertPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          headerTitle={headerTitle}
          content={content}
          setAlertValue={setAlertValue}
          cancelTitle={cancelTitle}
          confirmTitle={confirmTitle}
        />
      </div>
    </div>
  );
};

export default ManageFaqDetail;
