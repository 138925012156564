import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../../css/mileage/ManageMileageSeeMore.css";
import "../../css/Manage.css";
import MileageApi from "../../api/mileageApi";
import moment from "moment";
import ManageMileageHistory from "./ManageMileageHistory";
import { toast } from "react-toastify";

const ManageMileageSeeMore = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [historyOpen, setHistoryOpen] = useState(false);
  const [mileageId, setMileageId] = useState(null);
  const [mileageInfo, setMileageInfo] = useState({
    email: null,
    content: null,
    point: null,
    pointTotal: null,
    createDate: null,
    endDate: null,
  });

  useEffect(() => {
    if (location.state) {
      const { id } = location.state;
      setMileageId(location.state.id);
      getMileageInfo(id);
    }
  }, [location]);

  const getMileageInfo = async (id) => {
    try {
      const info = (await MileageApi.GetMileage(id)).data.data.content;

      let createDate = moment(info.createdAt).utc().format("YYYY/MM/DD HH:mm:ss");
      let endDate = moment(info.endDate).utc().format("YYYY.MM.DD");
      setMileageInfo((mileageInfo) => ({
        ...info,
        email: info.member.email,
        content: info.content,
        point: info.point,
        pointTotal: info.pointTotal,
        createDate: createDate,
        endDate: endDate,
      }));
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-mileage-seemore">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
          <div>
            <Link onClick={() => setHistoryOpen(true)}>
              이 회원의 포인트내역 보기
            </Link>
          </div>
        </div>
        <div className="Manage-form manage-form-mileage-see-more">
          <span>상세보기</span>
          <div className="Manage-form-view-details">
            <span>게시자 이메일</span>
            <span>{mileageInfo.email}</span>
          </div>
          <div className="Manage-form-view-details manage-flex-start">
            <span>마을리지 내용</span>
            <span>{mileageInfo.content}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>포인트</span>
            <span>{mileageInfo.point}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>포인트합</span>
            <span>{mileageInfo.pointTotal}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>일시</span>
            <span>{mileageInfo.createDate}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>만료일</span>
            <span>{mileageInfo.endDate}</span>
          </div>
        </div>
      </section>

      {historyOpen && (
        <ManageMileageHistory
          setHistoryOpen={setHistoryOpen}
          memberId={mileageInfo.member.id}
        />
      )}
    </div>
  );
};

export default ManageMileageSeeMore;
