import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../../css/Manage.css";
import "../../css/program/ManageProgramSeeMore.css";
import ProgramApi from "../../api/programApi";
import moment from "moment";
import { toast } from "react-toastify";
import AlertPopup from "../../components/AlertPopup";
import { downloadFile } from "../../utils/download";

const ManageProgramSeeMore = () => {
  let navigate = useNavigate();
  let location = useLocation();

  //--삭제 state--//
  const [isOpen, setIsOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("삭제 하시겠습니까?");
  const [content, setContent] = useState("삭제된 데이터는 복구가 불가능합니다.");
  const [alertValue, setAlertValue] = useState(false);
  const [cancelTitle, setCancelTitle] = useState("취소");
  const [confirmTitle, setConfirmTitle] = useState("삭제");
  //--삭제 state--//

  const [programId, setProgramId] = useState(null);
  const [programInfo, setProgramInfo] = useState({});

  useEffect(() => {
    try {
      if (location.state) {
        const { id } = location.state;
        setProgramId((id) => location.state.id);
        getProgramInfo(id);
      }
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  }, [location]);

  const getProgramInfo = async (id) => {
    try {
      const info = (await ProgramApi.GetProgram(id)).data.data.content;
      setProgramInfo(info);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  //삭제 실행하는 곳
  const programHandler = async () => {
    setIsOpen(true);
    setDeleteId(programId);
  };

  const [deleteId, setDeleteId] = useState(-1);
  useEffect(() => {
    const del = async () => {
      try {
        if (deleteId <= 0) {
          return;
        }
        await ProgramApi.DelProgram(deleteId);
        toast("삭제되었습니다.");
        navigate("/admin/program/1");
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    };
    if (alertValue) {
      del();
    }
  }, [alertValue]);

  const fileDownload = async (serverFileName, originFileName) => {
    try {
      const data = {
        serverFileName: serverFileName,
        originFileName: originFileName,
      };
      const response = await ProgramApi.FileDownload(data);
      downloadFile(response, originFileName);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };
  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-program-seemore">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
          <div>
            <Link onClick={programHandler}>삭제</Link>
            <Link to={`/admin/program/edit/${programId}`} state={{ id: programId }}>
              수정하기
            </Link>
          </div>
        </div>
        <div className="Manage-form manage-form-program-see-more">
          <span>상세</span>
          <div className="Manage-form-view-details Manage-form-view-details-program-seemore">
            <span>프로그램명</span>
            <span>{programInfo.title}</span>
          </div>
          <div className="Manage-form-view-details Manage-form-view-details-program-seemore">
            <span>소요시간</span>
            <span>{`${programInfo.requiredTime} 시간`}</span>
          </div>
          <div className="Manage-form-view-details Manage-form-view-details-program-seemore manage-flex-start">
            <span>상세설명</span>
            <span>{programInfo.content}</span>
          </div>
          <div className="Manage-form-view-details Manage-form-view-details-program-seemore">
            <span>
              이미지
              <br />
              (*최대 5장)
            </span>
            <div className="Manage-form-view-details-img Manage-form-view-details-img-program">
              {programInfo.programFiles &&
                programInfo.programFiles.map((item, index) => {
                  return (
                    <div className="program-detail-img-container">
                      <div className="program-detail-img">
                        <img src={item.imageUrl} />
                      </div>
                      <div>
                        <a
                          onClick={() => {
                            fileDownload(item.imageServerFileName, item.imageOriginFileName);
                          }}
                        >
                          <span>{item.imageOriginFileName}</span>
                          <img src="\img\download_black.svg" />
                        </a>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {/* 삭제 컴포넌트 */}
        <AlertPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          headerTitle={headerTitle}
          content={content}
          setAlertValue={setAlertValue}
          cancelTitle={cancelTitle}
          confirmTitle={confirmTitle}
        />
      </section>
    </div>
  );
};

export default ManageProgramSeeMore;
