import api from "./api";

export default class MeetingNoticeApi {
  static async Get(data) {
    return await api.get(`meeting-notice`, data);
  }

  static async GetNotice(id) {
    return await api.get(`meeting-notice/${id}`);
  }

  static async GetReview(data) {
    return await api.get(`meeting-notice-review`, data);
  }

  static async GetNoticeReviewInfo(id) {
    return await api.get(`meeting-notice-review/${id}`);
  }

  static async PostReview(id, data) {
    return await api.post(`meeting-notice-review/activation/${id}`, data);
  }

  static async PatchNotice(id, data) {
    return await api.patch(`meeting-notice/${id}`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }

  static async PostNotice(data) {
    return await api.post(`meeting-notice`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }
}
