import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import "../../css/gathering/ManageGatheringNoticeSeeMore.css";
import "../../css/Manage.css";
import ManageGatheringCommentPopup from "./ManageGatheringCommentPopup";
import MeetingNoticeApi from "../../api/meetingNoticeApi";
import moment from "moment";
import { toast } from "react-toastify";
const ManageGatheringNoticeSeeMore = () => {
  let location = useLocation();
  let navigate = useNavigate();

  const [date, setDate] = useState(null);
  const [noticeId, setNoticeId] = useState(null);
  const [noticeInfo, setNoticeInfo] = useState({});

  const [reviewInfo, setReviewInfo] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(null);

  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [activeCommentList, setActiveCommentList] = useState(["일반", "숨김"]);

  const [popup, setPopup] = useState(false);
  const [clickId, setClickId] = useState(null);

  let searchRef = useRef(null);
  const { id } = useParams();
  useEffect(() => {
      getNoticeInfo(id);    
      getNoticeReview();
  }, [location, currentPage]);

  useEffect(() => {     
    getNoticeReview();
}, [popup]);

  const getNoticeInfo = async () => {
    try {
      const info = (await MeetingNoticeApi.GetNotice(id)).data.data.content;
      let createDate = moment(info.createdAt).utc().format("YYYY/MM/DD");
      setDate(createDate);
      setNoticeId(info.id);
      setNoticeInfo(info);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const getNoticeReview = async () => {
    try {
      const info = (
        await MeetingNoticeApi.GetReview({
          meetingNoticeId: id,
          page: currentPage,
          size: 8,
        })
      ).data.data;
      setReviewInfo(info.content);
      setTotalCount(info.totalCount);
      setPostsPerPage(info.content.length);

      const count = Math.ceil(info.totalCount / info.content.length / 4);

      for (let i = 1; i <= count; i++) {
        if (currentPage / 4 <= i) {
          setStartIndex(4 * (i - 1));
          console.log();
          return;
        }
      }
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  useEffect(() => {
    let page = Math.ceil(totalCount / postsPerPage);
    let arr = [];
    for (var i = 1; i <= page; i++) {
      arr.push(i);
    }
    setPageIndex(arr);
  }, [totalCount]);

  const reviewActivation = async (id, state) => {
    let obj = {
      activation: state,
    };
    try {
      await MeetingNoticeApi.PostReview(id, obj);
      toast("변경되었습니다.");
      getNoticeReview();
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  useEffect(() => {
    function handleOutside(e) {
      if (searchRef.current && !searchRef.current.contains(e.target)) {
        setPopup(false);
      }
    }
    document.addEventListener("mousedown", handleOutside);
    return () => {
      document.removeEventListener("mousedown", handleOutside);
    };
  }, [searchRef]);

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-gathering-notice-seemore">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
          <div>
            <Link
              to={`/admin/gathering/detail/notice/edit/${noticeId}`}
              state={{ id: noticeId }}
            >
              수정하기
            </Link>
          </div>
        </div>
        <div className="Manage-form gathering-notice-seemore-info-container">
          <span>상세보기</span>
          <div className="Manage-form-view-details details-info-notice-seemore">
            <span>게시글 관리자 이메일</span>
            <span>{noticeInfo.manager && noticeInfo.manager.email}</span>
          </div>
          <div className="Manage-form-view-details details-info-notice-seemore">
            <span>게시일자</span>
            <span>{date}</span>
          </div>
          <div className="Manage-form-view-details details-info-notice-seemore">
            <span>제목</span>
            <span>{noticeInfo.title}</span>
          </div>
          <div className="Manage-form-view-details Manage-form-view-details-flexstart">
            <span>본문</span>
            <span>{noticeInfo.content}</span>
          </div>
          <div className="Manage-form-view-details Manage-form-view-details-flexstart">
            <span>상세이미지</span>
            <div className="schedule-notice-detail-img-container">
              <img className="uploaded-img" src={noticeInfo.imageUrl} />
              <div className="schedule-notice-detail-img-name">
                <a href="#" download>
                  <span>{noticeInfo.imageOriginFileName}</span>
                  <img src="\img\download_black.svg" />
                </a>
              </div>
            </div>
          </div>
          <div className="Manage-form-view-details details-info-notice-seemore">
            <span>첨부파일</span>
            <div className="schedule-notice-detail-file-name">
              <a href="#" download>
                <span>{noticeInfo.fileOriginFileName}</span>
                <img src="\img\download_black.svg" />
              </a>
            </div>
          </div>
          <div className="Manage-form-view-details details-info-notice-seemore">
            <span>댓글수</span>
            <span>{noticeInfo.reviewCount}개</span>
          </div>
          <div className="Manage-form-view-details details-info-notice-seemore">
            <span>좋아요수</span>
            <span>{noticeInfo.likeCount}개</span>
          </div>
        </div>
        <div className="Manage-form manage-form-gathering-notice-see-more-comment">
          <span>댓글</span>
          <table>
            <thead>
              <tr>
                <th>별명</th>
                <th>분류</th>
                <th>작성일자</th>
                <th>내용</th>
                <th>상태</th>
                <th>상세보기</th>
              </tr>
            </thead>
            <tbody>
              {reviewInfo.map((item, index) => {
                let createDate = moment(item.createdAt).utc().format("YYYY/MM/DD");
                return (
                  <tr key={index}>
                    <td>{item.member.nickname}</td>
                    <td>{item.id === item.parentId ? "댓글" : "대댓글"}</td>
                    <td>{createDate}</td>
                    <td>
                      <span>{item.content}</span>
                    </td>
                    <td className="table-sub-catagory-container">
                      <a
                        onClick={() => {
                          if (openSubmenu !== item.id) {
                            setOpenSubmenu(item.id);
                          } else {
                            openSubmenu
                              ? setOpenSubmenu(null)
                              : setOpenSubmenu(item.id);
                          }
                        }}
                      >
                        <span>{item.activation ? "일반" : "숨김"}</span>
                        <img src="\img\table-togle.svg" />
                      </a>
                      {openSubmenu === item.id ? (
                        <ul className="table-sub-menu">
                          <li
                            onClick={() => {
                              // let temp01 = [...data];
                              // let tempIndex = temp01.findIndex((item02) => {
                              //   return item02.id === item.id;
                              // });
                              // temp01[tempIndex].activation = true;

                              // setData(temp01);
                              reviewActivation(item.id, true);
                              setOpenSubmenu(null);
                            }}
                          >
                            일반
                          </li>
                          <li
                            onClick={() => {
                              reviewActivation(item.id, false);
                              setOpenSubmenu(null);
                            }}
                          >
                            숨김
                          </li>
                        </ul>
                      ) : null}
                    </td>
                    <td>
                      <a
                        onClick={() => {
                          setPopup(!popup);
                          setClickId(item.id)
                        }}
                      >
                        <img src="\img\see-more-btn-plus.svg" />
                        <span>보기</span>
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="manage-table-pagenation-container">
            <div className="manage-table-pagenation">
              <a
                onClick={() => {
                  if (currentPage <= 1) {
                    return;
                  } else {
                    setCurrentPage(currentPage - 1);
                  }
                }}
              >
                <img src="\img\chevron-left.svg" />
              </a>
              {pageIndex
                .slice(startIndex, startIndex + 4)
                .map((item, index) => {
                  return (
                    <a
                      key={index}
                      className={
                        currentPage === item ? "pagenation-focus" : null
                      }
                      onClick={() => {
                        setCurrentPage(item);
                      }}
                    >
                      {item}
                    </a>
                  );
                })}
              <a
                onClick={() => {
                  if (currentPage >= pageIndex.length) {
                    return;
                  } else {
                    setCurrentPage(currentPage + 1);
                  }
                }}
              >
                <img src="\img\chevron-right.svg" />
              </a>
            </div>
          </div>
        </div>
      </section>
      {popup && (
        <ManageGatheringCommentPopup
          searchRef={searchRef}
          setPopup={setPopup}
          noticeId={clickId}
          getNoticeReview={getNoticeReview}
        />
      )}
    </div>
  );
};

export default ManageGatheringNoticeSeeMore;
