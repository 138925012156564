import api, { _axios } from "./api";

export default class MeetingApi {
  static async getMeetings(data) {
    return await api.get(`meeting`, data);
  }

  static async setStatus(id, data) {
    return await api.post(`meeting/status/${id}`, data);
  }

  static async delMeeting(id, data) {
    return await api.del(`meeting/${id}`, data);
  }

  static async getSpaceList(data) {
    return await api.get(`space`, data);
  }

  static async getProgramList(data) {
    return await api.get(`program`, data);
  }

  static async getMeetingProgramList(data) {
    return await api.get(`meeting-program`, data);
  }

  static async postProgram(data) {
    return await api.post(`program`, data);
  }

  static async postMeeting(data) {
    return await api.post(`meeting`, data);
  }

  static async getMeeting(id, data) {
    return await api.get(`meeting/${id}`, data);
  }
  static async getMeetingPrograms(data) {
    return await api.get(`meeting-program`, data);
  }
  static async getMeetingMembers(data) {
    return await api.get(`meeting-member`, data);
  }
  static async patchMeeting(id, data) {
    return await api.patch(`meeting/${id}`, data);
  }
  static async postScheduleDate(data) {
    return await api.post(`meeting-schedule/date`, data);
  }
  static async getMeetingSchedule(data) {
    return await api.get(`meeting-schedule`, data);
  }
  static async DelMeetingMembers(id) {
    return await api.del(`meeting-member/${id}`);
  }
  static async PostMeetingMemberStatus(id, data) {
    return await api.post(`meeting-member/status/${id}`, data);
  }
  static async postMeetingScheduleProgram(data) {
    return await api.post(`meeting-schedule-program`, data);
  }
  static async patchMeetingScheduleProgram(id, data) {
    return await api.patch(`meeting-schedule-program/${id}`, data);
  }
  static async postMeetingSchedule(data) {
    return await api.post(`meeting-schedule`, data);
  }
  static async patchMeetingSchedule(id, data) {
    return await api.patch(`meeting-schedule/${id}`, data);
  }
  static async deleteMeetingScheduleProgram(id, data) {
    return await api.del(`meeting-schedule-program/${id}`, data);
  }
  static async deleteMeetingSchedule(id, data) {
    return await api.del(`meeting-schedule/${id}`, data);
  }
  static async deleteMeetingScheduleAll(meetingId, data) {
    return await api.del(`meeting-schedule/all/${meetingId}`, data);
  }

  static async FileDownload(data) {
    return await _axios.post(`meeting/download/file`, data, {
      responseType: "blob",
    });
  }   
}
