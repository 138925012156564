import api from "./api";

export default class MileageApi {
  static async Get(data) {
    return await api.get(`mileage`, data);
  }
  static async GetMileage(id) {
    return await api.get(`mileage/${id}`);
  }
  static async Post(data) {
    return await api.post(`mileage`, data);
  }
  static async Patch(id, data) {
    return await api.patch(`mileage/${id}`, data);
  }
}
