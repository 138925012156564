import api from "./api";

export default class FaqApi {
  static async PostFaq(data) {
    return await api.post(`faq`, data);
  }

  static async Get(id) {
    return await api.get(`faq/${id}`);
  }

  static async Del(id) {
    return await api.del(`faq/${id}`);
  }

  static async GetList(data) {
    return await api.get(`faq`, data);
  }

  static async PatchFaq(id, data) {
    return await api.patch(`faq/${id}`, data);
  }

  static async PostCategory(data) {
    return await api.post(`faq-category`, data);
  }

  static async PostFaqActivation(id, data) {
    return await api.post(`faq/activation/${id}`, data);
  }

  static async GetCategory(data) {
    return await api.get(`faq-category`, data);
  }

  static async postCategoryActivation(id, data) {
    return await api.post(`faq-category/activation/${id}`, data);
  }

  static async GetFaqCategory(id) {
    return await api.get(`faq-category/${id}`);
  }

  static async PatchFaqCategory(id, data) {
    return await api.patch(`faq-category/${id}`, data);
  }
}
