import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import "../../css/Manage.css";
import "../../css/generalmember/ManageGeneralMemberMessage.css";
import MemberApi from "../../api/memberApi";
import { toast } from "react-toastify";
const ManageGeneralMemberMessage = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const [memberData, setMemberData] = useState([]);
  const [smsInfo, setSmsInfo] = useState({
    to: [],
    title: null,
    content: null,
    inAll: false,
  });

  useEffect(() => {
    if (location && location.state) {
      if (location.state.tel) {
        setMemberData(location.state.tel);
      }
    }
  }, [location]);

  const send = async () => {
    const temp = {
      ...smsInfo,
      to: memberData.map((item) => item.tel),
    };
    try {
      if (memberData.length <= 0) {
        toast("문자를 보낼 사람을 선택해주세요.");
        return;
      }
      await MemberApi.Send(temp);
      toast("전송되었습니다.");
      navigate(-1);
    } catch (error) {}
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-general-member-adit">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
        </div>
        <div className="Manage-form manage-form-message-adit">
          <span>문자 보내기</span>
          <div className="Manage-input-container Manage-input-container-general-member-message general-member-info-flex-start">
            <span className="Manage-input-title">받는이</span>
            <div className="recipient-list">
              {memberData.length > 0
                ? memberData.map((item, index) => {
                    return (
                      <div key={index} className="recipient-detail">
                        <div>
                          <span>{item.name}</span>
                          <span>({item.tel})</span>
                        </div>
                        <img
                          src="/img/image-cancel.svg"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            let temp = memberData.filter((item02) => item02.id != item.id);
                            setMemberData(temp);
                          }}
                        />
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
          <div className="Manage-input-container Manage-input-container-general-member-message">
            <span className="Manage-input-title">제목</span>
            <input
              className="Manage-input-textarea manage-form-general-member-adit-input"
              type="text"
              placeholder="제목"
              required
              onChange={(e) => {
                setSmsInfo((item) => ({
                  ...item,
                  title: e.target.value,
                }));
              }}
            />
          </div>
          <div className="Manage-input-container Manage-input-container-general-member-message general-member-info-flex-start">
            <span className="Manage-input-title">내용</span>
            <textarea
              onChange={(e) => {
                setSmsInfo((item) => ({
                  ...item,
                  content: e.target.value,
                }));
              }}
            ></textarea>
          </div>
          <div className="Manage-form-save manage-form-save-general-member-adit">
            <button className="cursorPointer" onClick={send}>
              저장하기
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageGeneralMemberMessage;
