import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../../css/Manage.css";
import "../../css/permission/ManagePermissionsApproval.css";
import ManagerApi from "../../api/managerApi";
import { toast } from "react-toastify";

const ManagePermissionsApproval = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [access, setAccess] = useState([0]);
  const [managerId, setManagerId] = useState(0);
  const [managerData, setManagerData] = useState({});

  useEffect(() => {
    if (location.state) {
      const { id } = location.state;
      setManagerId(location.state.id);
      getManagerInfo(id);
    }
  }, [location]);

  const getManagerInfo = async (id) => {
    try {
      const info = (await ManagerApi.Get(id)).data.data.content;
      setManagerData((managerData) => info);
      let modifiedString = info.accessMenu.replace(/([a-zA-Z-]+)/g, '"$1"');
      let accessTemp = JSON.parse(modifiedString);
      setAccess((access) => accessTemp);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const checkHandler = (str) => {
    let copyData = [...access];
    if (copyData.includes(str)) {
      let index = copyData.indexOf(str);
      access.splice(index, 1);
      copyData.splice(index, 1);
      const string = `[${copyData.join(", ")}]`;
      setManagerData({
        ...managerData,
        accessMenu: string,
      });
    } else {
      access.push(str);
      copyData.push(str);
      const string = `[${copyData.join(", ")}]`;
      setManagerData({
        ...managerData,
        accessMenu: string,
      });
    }
  };

  const approvalHandler = async () => {
    let changeData = {
      email: managerData.email,
      password: "",
      passwordChangeFlag: false,
      managerAuthority: managerData.managerAuthority,
      accessMenu: managerData.accessMenu,
    };
    try {
      await ManagerApi.Approval(managerId, changeData);
      toast("승인되었습니다.");
      navigate("/admin/permission");
    } catch (err) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div
          className="go-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <Link>
            <img src="\img\chevron-left.svg" />
            <span>뒤로가기</span>
          </Link>
        </div>
        <div
          className="Manage-form manage-form-approval"
          style={{
            height: "877px",
          }}
        >
          <p>승인하기</p>

          <div className="manage-permissions-approval">
            <p className="manage-permissions-approval-title">이메일</p>
            <input
              type="email"
              className="manage-permissions-approval-input"
              name="email"
              value={managerData.email}
              onChange={(e) => {
                setManagerData({
                  ...managerData,
                  email: e.target.value,
                });
              }}
            />
          </div>
          <div className="manage-permissions-approval">
            <p className="manage-permissions-approval-title">비밀번호</p>
            <input type="password" className="manage-permissions-approval-input" name="password" disabled />
          </div>
          <div className="manage-permissions-approval">
            <p className="manage-permissions-approval-title">비밀번호 확인</p>
            <input type="password" className="manage-permissions-approval-input" name="passwordChangeFlag" disabled />
          </div>
          <div className="manage-permissions-approval-radio">
            <span className="manage-permissions-approval-title">권한</span>
            <div>
              <input
                className="cursorPointer"
                type="radio"
                name="right"
                id="admin1"
                value="ADMIN"
                checked={managerData.managerAuthority === "ADMIN" ? true : null}
                onChange={(e) => {
                  setManagerData({
                    ...managerData,
                    managerAuthority: e.target.value,
                    accessMenu: "[space-member, meeting, program, mileage]",
                  });
                }}
              />
              <label htmlFor="admin1">ADMIN</label>
              <input
                className="cursorPointer"
                type="radio"
                name="right"
                id="manager"
                value="MANAGER"
                checked={managerData.managerAuthority === "MANAGER" ? true : null}
                onChange={(e) => {
                  setManagerData({
                    ...managerData,
                    managerAuthority: e.target.value,
                  });
                }}
              />
              <label htmlFor="manager">MANAGER</label>

              <span>*공간 운영자 회원일 경우 MANAGER 권한을 부여합니다.</span>
            </div>
          </div>
          {managerData.managerAuthority === "MANAGER" ? (
            <div className="manage-permissions-approval-checkbox manage-permissions-approval-access-right">
              <span className="manage-permissions-approval-title">접근 권한</span>
              <div className="manage-permissions-approval-access-right-container">
                <div className="user-management">
                  <span>사용자 관리</span>
                  <input
                    type="checkbox"
                    name="user-management"
                    id="space-member"
                    checked={access.includes("space-member") ? true : null}
                    readOnly
                  />
                  <label htmlFor="space-member">공간회원 관리</label>
                </div>
                <div>
                  <span>운영 관리</span>
                  <input
                    type="checkbox"
                    name="operation-management"
                    id="meeting"
                    checked={access.includes("meeting") ? true : null}
                    readOnly
                  />
                  <label htmlFor="meeting">모임 관리</label>
                  <input
                    type="checkbox"
                    name="operation-management"
                    id="program"
                    checked={access.includes("program") ? true : null}
                    readOnly
                  />
                  <label htmlFor="program">프로그램 관리</label>
                  <input
                    type="checkbox"
                    name="operation-management"
                    id="mileage"
                    checked={access.includes("mileage") ? true : null}
                    readOnly
                  />
                  <label htmlFor="mileage">마을리지 관리</label>
                </div>
              </div>
            </div>
          ) : (
            <div className="manage-permissions-adit-checkbox manage-permissions-adit-access-right">
              <span className="manage-permissions-adit-title">접근 권한</span>
              <div className="access-right-container">
                <div className="user-management">
                  <span>사용자 관리</span>
                  <input
                    className="cursorPointer"
                    type="checkbox"
                    id="member"
                    value="member"
                    name="user-management"
                    checked={access.includes("member") ? true : null}
                    onChange={() => checkHandler("member")}
                  />
                  <label htmlFor="member">일반회원 관리</label>
                  <input
                    className="cursorPointer"
                    type="checkbox"
                    id="space-member"
                    value="space-member"
                    name="user-management"
                    checked={access.includes("space-member") ? true : null}
                    onChange={() => checkHandler("space-member")}
                  />
                  <label htmlFor="space-member">공간회원 관리</label>
                  <input
                    className="cursorPointer"
                    type="checkbox"
                    id="space-support"
                    name="user-management"
                    value="space-support"
                    checked={access.includes("space-support") ? true : null}
                    onChange={() => checkHandler("space-support")}
                  />
                  <label htmlFor="space-support">공간 지원 관리</label>
                  <input
                    className="cursorPointer"
                    type="checkbox"
                    id="withdraw-member"
                    name="user-management"
                    value="leave-member"
                    checked={access.includes("leave-member") ? true : null}
                    onChange={() => checkHandler("leave-member")}
                  />
                  <label htmlFor="withdraw-member">탈퇴회원 관리</label>
                </div>
                <div className="operation-management">
                  <span>운영 관리</span>
                  <input
                    className="cursorPointer"
                    type="checkbox"
                    id="meeting"
                    value="meeting"
                    name="operation-management"
                    checked={access.includes("meeting") ? true : null}
                    onChange={() => checkHandler("meeting")}
                  />
                  <label htmlFor="meeting">모임 관리</label>
                  <input
                    className="cursorPointer"
                    type="checkbox"
                    id="program"
                    value="program"
                    name="operation-management"
                    checked={access.includes("program") ? true : null}
                    onChange={() => checkHandler("program")}
                  />
                  <label htmlFor="program">프로그램 관리</label>
                  <input
                    className="cursorPointer"
                    type="checkbox"
                    id="mileage"
                    value="mileage"
                    name="operation-management"
                    checked={access.includes("mileage") ? true : null}
                    onChange={() => checkHandler("mileage")}
                  />
                  <label htmlFor="mileage">마을리지 관리</label>
                </div>
                <div className="community-management">
                  <span>커뮤니티 관리</span>
                  <input
                    className="cursorPointer"
                    type="checkbox"
                    id="comunity"
                    value="comunity"
                    name="community-management"
                    checked={access.includes("comunity") ? true : null}
                    onChange={() => checkHandler("comunity")}
                  />
                  <label htmlFor="comunity">커뮤니티 관리</label>
                  <input
                    className="cursorPointer"
                    type="checkbox"
                    id="call"
                    value="call"
                    name="community-management"
                    checked={access.includes("call") ? true : null}
                    onChange={() => checkHandler("call")}
                  />
                  <label htmlFor="call">신고 관리</label>
                </div>
                <div className="service-management service-manage-form">
                  <span>서비스 관리</span>
                  <input
                    className="cursorPointer"
                    type="checkbox"
                    name="service-management"
                    id="alarm"
                    value="alarm"
                    checked={access.includes("alarm") ? true : null}
                    onChange={() => checkHandler("alarm")}
                  />
                  <label htmlFor="alarm">알림 관리</label>
                  <input
                    className="cursorPointer"
                    type="checkbox"
                    name="service-management"
                    id="notice"
                    value="notice"
                    checked={access.includes("notice") ? true : null}
                    onChange={() => checkHandler("notice")}
                  />
                  <label htmlFor="notice">공지사항 관리</label>
                  <input
                    className="cursorPointer"
                    type="checkbox"
                    name="service-management"
                    id="faq"
                    value="faq"
                    checked={access.includes("faq") ? true : null}
                    onChange={() => checkHandler("faq")}
                  />
                  <label htmlFor="faq">FAQ 관리</label>
                  <input
                    className="cursorPointer"
                    type="checkbox"
                    name="service-management"
                    id="marker"
                    value="marker"
                    checked={access.includes("marker") ? true : null}
                    onChange={() => checkHandler("marker")}
                  />
                  <label htmlFor="marker">마커 관리</label>
                  <input
                    className="cursorPointer"
                    type="checkbox"
                    name="service-management"
                    id="popup"
                    value="popup"
                    checked={access.includes("popup") ? true : null}
                    onChange={() => checkHandler("popup")}
                  />
                  <label htmlFor="popup">팝업 관리</label>
                  <input
                    className="cursorPointer"
                    type="checkbox"
                    name="service-management"
                    id="terms"
                    value="terms"
                    checked={access.includes("terms") ? true : null}
                    onChange={() => checkHandler("terms")}
                  />
                  <label htmlFor="terms" style={{ marginRight: "20px" }}>
                    약관 관리
                  </label>
                  <input
                    className="cursorPointer"
                    type="checkbox"
                    name="service-management"
                    id="banner"
                    value="banner"
                    checked={access.includes("banner") ? true : null}
                    onChange={() => checkHandler("banner")}
                  />
                  <label htmlFor="banner">배너 관리</label>
                  <input
                    className="cursorPointer"
                    type="checkbox"
                    name="service-management"
                    id="open-source"
                    value="open-source"
                    checked={access.includes("open-source") ? true : null}
                    onChange={() => checkHandler("open-source")}
                  />
                  <label htmlFor="open-source">오픈소스 관리</label>
                </div>
                <div>
                  <span>환경설정</span>
                  <input
                    className="cursorPointer"
                    type="checkbox"
                    id="base"
                    name="right"
                    value="base"
                    checked={access.includes("base") ? true : null}
                    onChange={() => checkHandler("base")}
                  />
                  <label htmlFor="base">기본정보 관리</label>
                  <input
                    className="cursorPointer"
                    type="checkbox"
                    id="manager"
                    name="right"
                    value="manager"
                    checked={access.includes("manager") ? true : null}
                    onChange={() => checkHandler("manager")}
                  />
                  <label htmlFor="manager">관리자 권한 관리</label>
                </div>
              </div>
            </div>
          )}
          <div className="Manage-form-save manage-form-save-approval">
            <button className="cursorPointer" onClick={approvalHandler}>
              승인하기
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};
export default ManagePermissionsApproval;
