import { Link, useLocation, useNavigate, useNavigation, useParams, useSearchParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../../css/popup/ManagePopups.css";
import "../../css/Manage.css";
import PopupApi from "../../api/popupApi";
import moment from "moment";
import { toast } from "react-toastify";
import AlertPopup from "../../components/AlertPopup";

const ManagePopups = () => {
  let navigate = useNavigate();
  const { page } = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  //--삭제 state--//
  const [isOpen, setIsOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("삭제 하시겠습니까?");
  const [content, setContent] = useState("삭제된 데이터는 복구가 불가능합니다.");
  const [alertValue, setAlertValue] = useState(false);
  const [cancelTitle, setCancelTitle] = useState("취소");
  const [confirmTitle, setConfirmTitle] = useState("삭제");
  //--삭제 state--//

  const [popupList, setPopupList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(null);

  useEffect(() => {
    setCurrentPage(Number(page));
    getPopupList();
  }, [currentPage, location]);

  const getPopupList = async () => {
    try {
      const list = (await PopupApi.Get({ page: currentPage })).data.data;
      setPopupList((popupList) => list.content);
      setTotalCount(list.totalCount);
      setPostsPerPage(list.content.length);

      const count = Math.ceil(list.totalCount / list.content.length / 4);

      for (let i = 1; i <= count; i++) {
        if (currentPage / 4 <= i) {
          setStartIndex(4 * (i - 1));

          return;
        }
      }
    } catch (err) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  useEffect(() => {
    let page = Math.ceil(totalCount / postsPerPage);
    let arr = [];
    for (var i = 1; i <= page; i++) {
      arr.push(i);
    }
    setPageIndex(arr);
  }, [totalCount]);

  //삭제 실행하는 곳
  const delPopup = async (id) => {
    setIsOpen(true);
    setDeleteId(id);
  };

  const [deleteId, setDeleteId] = useState(-1);
  useEffect(() => {
    const del = async () => {
      try {
        if (deleteId <= 0) {
          return;
        }
        await PopupApi.Del(deleteId);
        toast("삭제되었습니다.");
        getPopupList();
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    };
    if (alertValue) {
      del();
    }
  }, [alertValue]);

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <span>팝업 관리</span>
        <div className="manage-table-container">
          <div className="manage-add-popups">
            <div>
              <span>전체 팝업 수</span>
              <span>{totalCount}</span>
            </div>
            <button
              onClick={() => {
                navigate("/admin/popups/add");
              }}
              className="cursorPointer"
            >
              <img src="\img\sidebar-plus-icon.svg" />
              <span>추가하기</span>
            </button>
          </div>
        </div>
        <div className="Manage-table manage-table-popups">
          <table>
            <thead>
              <tr>
                <th>제목</th>
                <th>등록일자</th>
                <th>삭제</th>
                <th>수정</th>
              </tr>
            </thead>
            <tbody>
              {popupList.map((item, index) => {
                let createDate = moment(item.createdAt).utc().format("YYYY/MM/DD");
                return (
                  <tr>
                    <td>{popupList[index].title}</td>
                    <td>{createDate}</td>
                    <td>
                      <a onClick={() => delPopup(item.id)}>삭제</a>
                    </td>
                    <td>
                      <Link to={`/admin/popups/edit/${item.id}`} state={{ id: item.id }}>
                        수정
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="manage-table-pagenation-container">
            <div className="manage-table-pagenation">
              <a
                onClick={() => {
                  if (currentPage <= 1) {
                    return;
                  } else {
                    navigate(`/admin/popup/${currentPage - 1}`);
                  }
                }}
              >
                <img src="\img\chevron-left.svg" />
              </a>
              {pageIndex.slice(startIndex, startIndex + 4).map((item, index) => {
                return (
                  <a
                    key={index}
                    className={currentPage === item ? "pagenation-focus" : null}
                    onClick={() => {
                      navigate(`/admin/popup/${item}`);
                    }}
                  >
                    {item}
                  </a>
                );
              })}
              <a
                onClick={() => {
                  if (currentPage >= pageIndex.length) {
                    return;
                  } else {
                    navigate(`/admin/popup/${currentPage + 1}`);
                  }
                }}
              >
                <img src="\img\chevron-right.svg" />
              </a>
            </div>
          </div>
        </div>
        {/* 삭제 컴포넌트 */}
        <AlertPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          headerTitle={headerTitle}
          content={content}
          setAlertValue={setAlertValue}
          cancelTitle={cancelTitle}
          confirmTitle={confirmTitle}
        />
      </section>
    </div>
  );
};

export default ManagePopups;
