import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../../css/Manage.css";
import "../../css/space/ManageSpaceAdd.css";
import ManagerApi from "../../api/managerApi";
import SpaceApi from "../../api/spaceApi";
import MarkerApi from "../../api/marker";
import { toast } from "react-toastify";
import { async } from "q";

const ManageSpaceAdd = () => {
  let navigate = useNavigate();

  const [duplCheck, setDuplCheck] = useState(false);
  const [subMenu, setSubMenu] = useState([]);
  const [spaceFile, setSpaceFile] = useState([]);
  const [managerInfo, setManagerInfo] = useState({});
  const [selectMenu, setSelectMenu] = useState(false);
  const [selectedCatagory, setSelectedCatagory] = useState(false);
  const [selectMenuText, setSelectMenuText] = useState("공간 카테고리를 선택하세요");
  const [spaceInfo, setSpaceInfo] = useState({
    managerId: null,
    name: null,
    managerName: null,
    phoneNumber: null,
    email: null,
    companyName: null,
    account: null,
    accountUrl: null,
    idCard: null,
    idCardUrl: null,
    spaceCategory: null,
    profile: null,
    profileUrl: null,
    background: null,
    backgroundUrl: null,
    intro: null,
    subCategory: null,
    businessHours: null,
    address: null,
    lat: null,
    lon: null,
    tell: null,
    image: null,
    imageUrl: null,
    images: [],
    detail: null,
  });

  useEffect(() => {
    const getManager = async () => {
      try {
        const info = (await ManagerApi.Get(sessionStorage.getItem("id"))).data.data.content;
        setManagerInfo(info);
        setSpaceInfo({
          ...spaceInfo,
          managerId: info.id,
        });

        const marker = (await MarkerApi.Get()).data.data.content;
        setSubMenu(marker);
      } catch (error) {
        console.log(error);
      }
    };
    getManager();
  }, []);

  // useEffect(() => {
  //   setSpaceInfo({
  //     ...spaceInfo,
  //     spaceCategory: "CAFE",
  //   });
  // }, [selectMenuText]);

  useEffect(() => {
    setSpaceInfo((spaceInfo) => ({
      ...spaceInfo,
      images: spaceFile,
    }));
  }, [spaceFile]);

  const spaceImg = (e, type, url, flag) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setSpaceInfo((spaceInfo) => ({
        ...spaceInfo,
        [type]: file,
        [url]: e.target.result,
        [flag]: true,
      }));
    };
    reader.readAsDataURL(file);
    e.target.value = "";
  };

  const spaceImgs = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const arr = [...spaceFile];
      const obj = {};
      obj.image = file;
      obj.imageUrl = e.target.result;
      arr.push(obj);
      setSpaceFile((prev) => {
        return arr;
      });
      setSpaceInfo((spaceInfo) => ({
        ...spaceInfo,
        images: spaceFile,
      }));
    };
    reader.readAsDataURL(file);
    e.target.value = "";
  };

  const deleteImage = (type, url, flag) => {
    setSpaceInfo((spaceInfo) => ({
      ...spaceInfo,
      [type]: null,
      [url]: "",
      [flag]: true,
    }));
  };

  const deleteImages = (index) => {
    let copyFile = [...spaceFile];
    copyFile.splice(index, 1);
    let copyData = [...spaceInfo.images];
    copyData.splice(index, 1);
    setSpaceInfo((spaceInfo) => ({
      ...spaceInfo,
      images: copyData,
    }));
    setSpaceFile(copyFile);
  };

  const spaceAdd = async () => {
    if (!duplCheck) {
      toast("공간명 중복을 확인해주세요");
    } else {
      try {
        const formData = new FormData();
        formData.append("managerId", spaceInfo.managerId);
        formData.append("name", spaceInfo.name);
        formData.append("managerName", spaceInfo.managerName);
        formData.append("phoneNumber", spaceInfo.phoneNumber);
        formData.append("email", spaceInfo.email);
        formData.append("companyName", spaceInfo.companyName);
        if (spaceInfo.account) {
          formData.append("account", spaceInfo.account);
        }
        if (spaceInfo.idCard) {
          formData.append("idCard", spaceInfo.idCard);
        }
        if (spaceInfo.spaceCategory) {
          formData.append("spaceCategory", spaceInfo.spaceCategory);
        }
        if (spaceInfo.profile) {
          formData.append("profile", spaceInfo.profile);
        }
        if (spaceInfo.background) {
          formData.append("background", spaceInfo.background);
        }
        formData.append("intro", spaceInfo.intro);
        formData.append("subCategory", spaceInfo.subCategory);
        formData.append("businessHours", spaceInfo.businessHours);
        formData.append("address", spaceInfo.address);
        formData.append("lat", spaceInfo.lat);
        formData.append("lon", spaceInfo.lon);
        formData.append("tell", spaceInfo.tell);
        if (spaceInfo.image) {
          formData.append("image", spaceInfo.image);
        }
        // if (spaceInfo.images) {
        //   spaceInfo.images.forEach((item, index) => {
        //     formData.append(`images`, item.image);
        //   });
        // }
        if (spaceInfo.images) {
          for (let i = 0; i < spaceInfo.images.length; i++) {
            formData.append(`images`, spaceInfo.images[i].image);
          }
        }
        // if (spaceInfo.images) {
        //   spaceInfo.images.forEach((item, index) => {
        //     formData.append("images[]", item);
        //   });
        // }
        // if (spaceInfo.images) {
        //   formData.append("images", spaceFile.image);
        // }
        formData.append("detail", spaceInfo.detail);

        await SpaceApi.Post(formData);
        toast("추가되었습니다.");
        navigate("/admin/space/1");
      } catch (error) {
        console.log(error);
        if (error.response.status === 413) {
          toast(`이미지 용량을 초과하였습니다.`);
        }
      }
    }
  };

  const execDaumPostcode = () => {
    let geocoder = new window.kakao.maps.services.Geocoder();
    new window.daum.Postcode({
      oncomplete: (data) => {
        if (data.userSelectedType === "R") {
          // 사용자가 도로명 주소를 선택했을 경우
          geocoder.addressSearch(`${data.roadAddress}`, function (result, status) {
            // 정상적으로 검색이 완료됐으면
            if (status === window.kakao.maps.services.Status.OK) {
              setSpaceInfo({
                ...spaceInfo,
                address: data.roadAddress,
                lat: result[0].y,
                lon: result[0].x,
              });
            }
          });
        } else {
          // 사용자가 지번 주소를 선택했을 경우(J)
          geocoder.addressSearch(`${data.roadAddress}`, function (result, status) {
            // 정상적으로 검색이 완료됐으면
            if (status === window.kakao.maps.services.Status.OK) {
              setSpaceInfo({
                ...spaceInfo,
                address: data.jibunAddress,
                lat: result[0].y,
                lon: result[0].x,
              });
            }
          });
        }
      },
    }).open();
  };

  const checkName = async () => {
    let obj = {
      name: spaceInfo.name,
    };
    try {
      await SpaceApi.GetCheck(obj);
      toast("확인되었습니다");
      setDuplCheck(true);
    } catch (error) {
      toast(error.response.data.message);
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-space-add">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
        </div>
        <div className="Manage-form manage-form-space-add">
          <span>추가하기</span>
          <div>
            <span>기본정보</span>
            <div className="Manage-input-container Manage-input-container-space-add">
              <span className="Manage-input-title">등록관리자이메일</span>
              <input
                className="Manage-input-textarea manage-form-space-add-input"
                type="email"
                placeholder="이메일"
                disabled
                value={managerInfo.email}
              />
            </div>
            <div className="Manage-input-container Manage-input-container-space-add">
              <span className="Manage-input-title">공간명*</span>
              <input
                className="Manage-input-textarea manage-form-space-add-input"
                type="text"
                placeholder="공간명을 입력하세요"
                required
                onChange={(e) => {
                  setSpaceInfo({
                    ...spaceInfo,
                    name: e.target.value,
                  });
                }}
              />
              <a className="space-name-duplication-check" onClick={checkName}>
                공간명 중복 확인
              </a>
            </div>
            <div className="Manage-input-container Manage-input-container-space-add">
              <span className="Manage-input-title">담당자명*</span>
              <input
                className="Manage-input-textarea manage-form-space-add-input"
                type="text"
                placeholder="담당자명을 입력하세요"
                required
                onChange={(e) => {
                  setSpaceInfo({
                    ...spaceInfo,
                    managerName: e.target.value,
                  });
                }}
              />
            </div>
            <div className="Manage-input-container Manage-input-container-space-add">
              <span className="Manage-input-title">휴대전화*</span>
              <input
                className="Manage-input-textarea manage-form-space-add-input"
                type="tel"
                placeholder="휴대전화번호를 입력하세요. (숫자만 입력가능)"
                required
                onChange={(e) => {
                  setSpaceInfo({
                    ...spaceInfo,
                    phoneNumber: e.target.value,
                  });
                }}
              />
            </div>
            <div className="Manage-input-container Manage-input-container-space-add">
              <span className="Manage-input-title">이메일*</span>
              <input
                className="Manage-input-textarea manage-form-space-add-input"
                type="email"
                placeholder="이메일을 입력하세요"
                required
                onChange={(e) => {
                  setSpaceInfo({
                    ...spaceInfo,
                    email: e.target.value,
                  });
                }}
              />
            </div>
            <div className="Manage-input-container Manage-input-container-space-add">
              <span className="Manage-input-title">상호(기업)명*</span>
              <input
                className="Manage-input-textarea manage-form-space-add-input"
                type="text"
                placeholder="상호(기업)명을 입력하세요"
                required
                onChange={(e) => {
                  setSpaceInfo({
                    ...spaceInfo,
                    companyName: e.target.value,
                  });
                }}
              />
            </div>
            <div className="Manage-input-container manage-flex-start">
              <span className="Manage-input-title">통장사본</span>
              <div className="space-add-input-file-container">
                <label htmlFor="account-file-input">
                  <img src="\img\file-upload-plus.svg" />
                  <span>파일 등록</span>
                </label>
                <input
                  id="account-file-input"
                  className="Manage-input-textarea manage-form-space-add-input"
                  type="file"
                  required
                  onChange={(e) => {
                    spaceImg(e, "account", "accountUrl", "accountDeleteFlag");
                  }}
                />
                {spaceInfo.account ? (
                  <div className="space-img">
                    <img className="uploaded-img" src={spaceInfo?.accountUrl} />
                    <img
                      className="space-img-cancel cursorPointer"
                      src="\img\image-cancel.svg"
                      onClick={(e) => deleteImage("account", "accountUrl", "accountDeleteFlag")}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="Manage-input-container manage-flex-start input-container-last">
              <span className="Manage-input-title">
                신분증
                <br />
                (개인일 경우 등록)
              </span>
              <div className="space-add-input-file-container">
                <label htmlFor="id-file-input">
                  <img src="\img\file-upload-plus.svg" />
                  <span>파일 등록</span>
                </label>
                <input
                  id="id-file-input"
                  className="Manage-input-textarea manage-form-space-add-input"
                  type="file"
                  onChange={(e) => {
                    spaceImg(e, "idCard", "idCardUrl", "idCardDeleteFlag");
                  }}
                />
                {spaceInfo.idCard ? (
                  <div className="space-img">
                    <img className="uploaded-img" src={spaceInfo?.idCardUrl} />
                    <img
                      className="space-img-cancel cursorPointer"
                      src="\img\image-cancel.svg"
                      onClick={(e) => deleteImage("idCard", "idCardUrl", "idCardDeleteFlag")}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div>
            <span className="space-add-title">프로필 관리</span>
            <div className="Manage-input-container Manage-input-container-space-add">
              <p className="Manage-input-title">카테고리*</p>
              <div className="select">
                <div className="selected" onClick={() => setSelectMenu(!selectMenu)}>
                  <div className={selectedCatagory ? "selected-value" : "selected-value-basic"}>{selectMenuText}</div>
                  <div className="select-toggle-btn">
                    <img src="\img\table-togle.svg" />
                  </div>
                </div>
                {selectMenu ? (
                  <ul className="option-container">
                    {subMenu.map((item, index) => {
                      return (
                        <li
                          className="option"
                          onClick={() => {
                            setSelectMenuText(
                              item.spaceCategory === "CAFE"
                                ? "카페·제과"
                                : item.spaceCategory === "RESTAURANT"
                                ? "맛집"
                                : item.spaceCategory === "PUB"
                                ? "술집"
                                : item.spaceCategory === "TOUR"
                                ? "관광지"
                                : item.spaceCategory === "LIFE"
                                ? "생활"
                                : item.spaceCategory === "EXPERIENCE"
                                ? "체험"
                                : item.spaceCategory === "SHOP"
                                ? "가게"
                                : item.spaceCategory === "ETC"
                                ? "기타"
                                : null
                            );
                            setSelectedCatagory(true);
                            setSelectMenu(false);
                            setSpaceInfo((spaceInfo) => ({
                              ...spaceInfo,
                              spaceCategory: item.spaceCategory,
                            }));
                          }}
                        >
                          {item.spaceCategory === "CAFE"
                            ? "카페·제과"
                            : item.spaceCategory === "RESTAURANT"
                            ? "맛집"
                            : item.spaceCategory === "PUB"
                            ? "술집"
                            : item.spaceCategory === "TOUR"
                            ? "관광지"
                            : item.spaceCategory === "LIFE"
                            ? "생활"
                            : item.spaceCategory === "EXPERIENCE"
                            ? "체험"
                            : item.spaceCategory === "SHOP"
                            ? "가게"
                            : item.spaceCategory === "ETC"
                            ? "기타"
                            : null}
                        </li>
                      );
                    })}
                  </ul>
                ) : null}
              </div>
            </div>
            <div className="Manage-input-container Manage-input-container-space-add">
              <span className="Manage-input-title">서브 카테고리</span>
              <input
                className="Manage-input-textarea manage-form-space-add-input"
                type="text"
                placeholder="ooo, ooo, ooo 형태로 입력하세요"
                onChange={(e) => {
                  setSpaceInfo({
                    ...spaceInfo,
                    subCategory: e.target.value,
                  });
                }}
              />
            </div>
            <div className="Manage-input-container manage-flex-start">
              <span className="Manage-input-title">프로필 이미지*</span>
              <div className="space-add-input-file-container">
                <label htmlFor="profile-file-input">
                  <img src="\img\file-upload-plus.svg" />
                  <span>파일 등록</span>
                </label>
                <input
                  id="profile-file-input"
                  className="Manage-input-textarea manage-form-space-add-input"
                  type="file"
                  required
                  onChange={(e) => {
                    spaceImg(e, "profile", "profileUrl", "profileDeleteFlag ");
                  }}
                />
                {spaceInfo.profile ? (
                  <div className="space-img">
                    <img className="uploaded-img" src={spaceInfo?.profileUrl} />
                    <img
                      className="space-img-cancel cursorPointer"
                      src="\img\image-cancel.svg"
                      onClick={(e) => deleteImage("profile", "profileUrl", "profileDeleteFlag ")}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="Manage-input-container manage-flex-start">
              <span className="Manage-input-title">배경 이미지*</span>
              <div className="space-add-input-file-container">
                <label htmlFor="back-file-input">
                  <img src="\img\file-upload-plus.svg" />
                  <span>파일 등록</span>
                </label>
                <input
                  id="back-file-input"
                  className="Manage-input-textarea manage-form-space-add-input"
                  type="file"
                  required
                  onChange={(e) => {
                    spaceImg(e, "background", "backgroundUrl", "backgroundDeleteFlag");
                  }}
                />
                {spaceInfo.background ? (
                  <div className="space-img">
                    <img className="uploaded-img" src={spaceInfo?.backgroundUrl} />
                    <img
                      className="space-img-cancel cursorPointer"
                      src="\img\image-cancel.svg"
                      onClick={(e) => deleteImage("background", "backgroundUrl", "backgroundDeleteFlag")}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="Manage-input-container Manage-input-container-space-add manage-flex-start">
              <span className="Manage-input-title">공간 소개글*</span>
              <textarea
                placeholder="공간 소개글을 입력하세요 (띄어쓰기 포함 200자 이내 작성)"
                maxLength="200"
                onChange={(e) => {
                  setSpaceInfo({
                    ...spaceInfo,
                    intro: e.target.value,
                  });
                }}
              ></textarea>
            </div>
            <div className="Manage-input-container Manage-input-container-space-add manage-flex-start">
              <span className="Manage-input-title">운영시간*</span>
              <textarea
                placeholder="운영시간을 입력하세요"
                onChange={(e) => {
                  setSpaceInfo({
                    ...spaceInfo,
                    businessHours: e.target.value,
                  });
                }}
              ></textarea>
            </div>
            <div className="Manage-input-container Manage-input-container-space-add">
              <span className="Manage-input-title">주소*</span>
              <input
                className="Manage-input-textarea manage-form-space-add-input space-add-search-address"
                type="text"
                placeholder="주소를 입력하세요"
                value={spaceInfo.address}
                required
                onClick={execDaumPostcode}
              />
            </div>
            {/* <div className="Manage-input-container Manage-input-container-space-add">
              <span className="Manage-input-title">위도(y좌표)*</span>
              <input
                className="Manage-input-textarea manage-form-space-add-input"
                type="text"
                placeholder="위도를 입력하세요"
                required
                onChange={(e) => {
                  setSpaceInfo({
                    ...spaceInfo,
                    lat: e.target.value,
                  });
                }}
              />
            </div>
            <div className="Manage-input-container Manage-input-container-space-add">
              <span className="Manage-input-title">경도(x좌표)*</span>
              <input
                className="Manage-input-textarea manage-form-space-add-input"
                type="text"
                placeholder="경도를 입력하세요"
                required
                onChange={(e) => {
                  setSpaceInfo({
                    ...spaceInfo,
                    lon: e.target.value,
                  });
                }}
              />
            </div> */}
            <div className="Manage-input-container Manage-input-container-space-add">
              <span className="Manage-input-title">연락처*</span>
              <input
                className="Manage-input-textarea manage-form-space-add-input"
                type="text"
                placeholder="연락처를 입력하세요"
                required
                onChange={(e) => {
                  setSpaceInfo({
                    ...spaceInfo,
                    tell: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div>
            <span className="space-add-title">상세설명</span>
            <div className="Manage-input-container manage-flex-start">
              <span className="Manage-input-title">대표 이미지</span>
              <div className="space-add-input-file-container">
                <label htmlFor="img-file-input">
                  <img src="\img\file-upload-plus.svg" />
                  <span>파일 등록</span>
                </label>
                <input
                  id="img-file-input"
                  className="Manage-input-textarea manage-form-space-add-input"
                  type="file"
                  required
                  onChange={(e) => {
                    spaceImg(e, "image", "imageUrl", "imageDeleteFlag");
                  }}
                />
                {spaceInfo.image ? (
                  <div className="space-img">
                    <img className="uploaded-img" src={spaceInfo?.imageUrl} />
                    <img
                      src="\img\image-cancel.svg"
                      className="space-img-cancel cursorPointer"
                      onClick={(e) => deleteImage("image", "imageUrl", "imageDeleteFlag")}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="Manage-input-container manage-flex-start">
              <span className="Manage-input-title">상세 이미지</span>
              <div className="space-add-input-file-container">
                <label htmlFor="files-input">
                  <img src="\img\file-upload-plus.svg" />
                  <span>파일 등록</span>
                </label>
                <input
                  id="files-input"
                  multiple
                  className="Manage-input-textarea manage-form-space-add-input"
                  type="file"
                  required
                  onChange={(e) => {
                    spaceImgs(e);
                  }}
                />
                {spaceInfo.images &&
                  spaceInfo.images.map((item, index) => {
                    return (
                      <div className="space-img uploaded-imgs" key={index}>
                        <img className="uploaded-img" src={spaceInfo.images[index].imageUrl} />
                        <img
                          className="space-img-cancel cursorPointer"
                          src="\img\image-cancel.svg"
                          onClick={(e) => deleteImages(index)}
                        />
                      </div>
                    );
                  })}
                {/* {spaceInfo.images ? (
                  <div className="space-img">
                    <img className="uploaded-img" src={spaceInfo?.imageUrl} />
                    <img
                      className="space-img-cancel"
                      src="\img\image-cancel.svg"
                      onClick={(e) =>
                        deleteImage("images", "spaceFiles", "imageDeleteFlag")
                      }
                    />
                  </div>
                ) : null} */}
              </div>
            </div>
            <div className="Manage-input-container Manage-input-container-space-add manage-flex-start">
              <span className="Manage-input-title">상세설명</span>
              <textarea
                placeholder="공간 상세설명을 입력하세요 (띄어쓰기 포함 1000자 이내 작성)"
                maxLength="1000"
                onChange={(e) => {
                  setSpaceInfo({
                    ...spaceInfo,
                    detail: e.target.value,
                  });
                }}
              ></textarea>
            </div>
          </div>
          <div className="Manage-form-save manage-form-save-space-add">
            <button className="cursorPointer" onClick={spaceAdd}>
              저장하기
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageSpaceAdd;
