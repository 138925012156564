import api from "./api";

export default class SpaceSupportApi {
  static async Get(data) {
    return await api.get(`space-support`, data);
  }
  static async GetSpaceSupport(id) {
    return await api.get(`space-support/${id}`);
  }
  static async PostStatus(id, data) {
    return await api.post(`space-support/status/${id}`, data);
  }
  static async Del(id) {
    return await api.del(`space-support/${id}`);
  }
}
