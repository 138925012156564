import api from "./api";

export default class CommunityApi {
  static async Get(data) {
    return await api.get(`community`, data);
  }
  static async GetNotice(id, data) {
    return await api.get(`community/${id}`, data);
  }
  static async DelNotice(id) {
    return await api.del(`community/${id}`);
  }
  static async PatchNotice(id, data) {
    return await api.patch(`community/${id}`, data);
  }
  static async GetCommunityReview(data) {
    return await api.get(`community-review`, data);
  }
  static async GetCommunityReviewInfo(id) {
    return await api.get(`community-review/${id}`);
  }
  static async reviewActivation(id, data) {
    return await api.post(`community-review/activation/${id}`, data);
  }
}
