import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../../css/space/ManageSpace.css";
import "../../css/Manage.css";
import SpaceApi from "../../api/spaceApi";
import { toast } from "react-toastify";
import AlertPopup from "../../components/AlertPopup";
import { saveAs } from "file-saver";

const ManageSpace = () => {
  let navigate = useNavigate();
  const { page } = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  //--삭제 state--//
  const [isOpen, setIsOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("삭제 하시겠습니까?");
  const [content, setContent] = useState("삭제된 데이터는 복구가 불가능합니다.");
  const [alertValue, setAlertValue] = useState(false);
  const [cancelTitle, setCancelTitle] = useState("취소");
  const [confirmTitle, setConfirmTitle] = useState("삭제");
  //--삭제 state--//

  //------------엑셀 생성
  const downloadExcel = async () => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    workbook.addWorksheet("Sheet One");
    const sheetOne = workbook.getWorksheet("Sheet One");

    sheetOne.columns = [
      { header: "공간명", key: "name", width: 40 },
      { header: "분류", key: "type", width: 40 },
      { header: "상호명", key: "companyName", width: 40 },
      { header: "담당자명", key: "managerName", width: 40 },
      { header: "휴대전화", key: "phoneNumber", width: 40 },
    ];

    excel.map((item, index) => {
      // 행 추가
      sheetOne.addRow(item);
    });
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "공간 관리.xlsx");
  };
  //------------액셀 부분 끝

  //
  const [arr, setArr] = useState([]);
  const [excel, setExcel] = useState([]);

  const [spaceData, setSpaceData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [subMenu, setSubMenu] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(null);

  useEffect(() => {
    setSearchKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    setCurrentPage(Number(page));
    getNoticeList();
  }, [currentPage, location]);

  const getNoticeList = async () => {
    try {
      const list = (
        await SpaceApi.Get({
          page: currentPage,
          size: 10,
          keyword: searchParams.get("keyword"),
          managerId: sessionStorage.getItem("managerAuthority") === "MANAGER" ? sessionStorage.getItem("id") : "",
        })
      ).data.data;
      setSpaceData(list.content);
      setTotalCount(list.totalCount);
      setPostsPerPage(list.content.length);

      const count = Math.ceil(list.totalCount / list.content.length / 4);

      for (let i = 1; i <= count; i++) {
        if (currentPage / 4 <= i) {
          setStartIndex(4 * (i - 1));
          return;
        }
      }
    } catch (err) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  useEffect(() => {
    let page = Math.ceil(totalCount / postsPerPage);
    let arr = [];
    for (var i = 1; i <= page; i++) {
      arr.push(i);
    }
    setPageIndex(arr);
  }, [totalCount]);

  // const searchSpace = async () => {
  //   try {
  //     const searchList = (await SpaceApi.Get({ keyword: searchKeyword })).data
  //       .data;
  //     setSpaceData(searchList.content);
  //     setTotalCount(searchList.totalCount);
  //   } catch (error) {
  //     toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
  //   }
  // };

  const delSpace = async (id) => {
    setIsOpen(true);
    setDeleteId(id);
  };

  const [deleteId, setDeleteId] = useState(-1);
  useEffect(() => {
    const del = async () => {
      try {
        if (deleteId <= 0) {
          return;
        }
        await SpaceApi.Del(deleteId);
        toast("삭제되었습니다.");
        getNoticeList();
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    };
    if (alertValue) {
      del();
    }
  }, [alertValue]);

  const spaceCategory = async (id, index, state) => {
    let copyData = [...spaceData];
    copyData[index].type = state;
    setSpaceData(copyData);
    let obj = {
      type: state,
    };
    try {
      await SpaceApi.PostType(id, obj);
      toast("변경되었습니다.");
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents Manage-contents-table">
        <span>공간 관리</span>
        <div className="manage-table-container">
          <div className="Manage-space-search-container">
            <div className="Manage-space-search-number">
              <span>전체 갯수</span>
              <span>{totalCount}</span>
            </div>
            <div className="Manage-space-search">
              <div style={{ position: "relative" }}>
                <input
                  className="table-search-input"
                  type="text"
                  placeholder="검색어를 입력하세요"
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                  }}
                  value={searchKeyword}
                />
                <img
                  src="/img/search.svg"
                  alt=""
                  style={{
                    position: "absolute",
                    right: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/admin/space/1?keyword=${searchKeyword}`);
                  }}
                />
              </div>
              {sessionStorage.getItem("managerAuthority") === "MANAGER" && totalCount > 0 ? null : (
                <div className="cursorPointer" onClick={() => navigate("/admin/space/add")}>
                  <img src="\img\see-more-btn-plus.svg" />
                  <span>공간 추가</span>
                </div>
              )}
              <div onClick={downloadExcel} className="cursorPointer">
                <img src="\img\download.svg" />
                <span>엑셀로 내보내기</span>
              </div>
            </div>
          </div>
          <div className="Manage-table manage-table-space">
            <table>
              <thead>
                <tr>
                  <th>
                    <input
                      className="cursorPointer all-check-input"
                      type="checkbox"
                      id={"check"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          let obj = [];
                          const temp = spaceData.map((value) => value.id);
                          const excelTemp = spaceData.map((item) => {
                            obj.push({
                              name: item.name,
                              type: `${item.type ? "일반공간" : "협력공간"}`,
                              companyName: item.companyName,
                              managerName: item.managerName,
                              phoneNumber: item.phoneNumber,
                            });
                          });
                          setArr(temp);
                          setExcel(obj);
                        } else {
                          setArr([]);
                          setExcel([]);
                        }
                        console.log(excel);
                      }}
                    ></input>
                    <label className="all-check-label" htmlFor={"check"}></label>
                  </th>
                  <th>공간명</th>
                  <th>분류</th>
                  <th>상호명</th>
                  <th>담당자명</th>
                  <th>휴대전화</th>
                  <th>공간 삭제</th>
                  <th>상세보기</th>
                </tr>
              </thead>
              <tbody>
                {spaceData.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <input
                          className="cursorPointer"
                          type="checkbox"
                          id={"check" + index}
                          checked={arr.includes(item.id)}
                          onChange={(e) => {
                            let obj = {
                              name: item.name,
                              type: `${item.type ? "일반공간" : "협력공간"}`,
                              companyName: item.companyName,
                              managerName: item.managerName,
                              phoneNumber: item.phoneNumber,
                            };
                            let copyData = [...arr];
                            let copyExcel = [...excel];
                            if (e.target.checked) {
                              copyData.push(item.id);
                              copyExcel.push(obj);
                              setArr(copyData);
                              setExcel(copyExcel);
                            } else {
                              let arrTemp = copyData.filter((item02) => item02 !== item.id);
                              let excelTemp = copyExcel.filter((item02) => item02.name !== item.name);
                              setArr(arrTemp);
                              setExcel(excelTemp);
                            }
                          }}
                        ></input>
                        <label htmlFor={"check" + index}></label>
                      </td>
                      <td>
                        <span>{item.name}</span>
                      </td>
                      <td className="table-sub-catagory-container">
                        <a
                          onClick={() => {
                            if (sessionStorage.getItem("managerAuthority") === "MANAGER") {
                              return;
                            }

                            if (subMenu === index) {
                              setSubMenu(null);
                            } else {
                              setSubMenu(index);
                            }
                          }}
                        >
                          <span>{item.type ? "협력공간" : "일반공간"}</span>
                          <img src="\img\table-togle.svg" />
                        </a>
                        {subMenu === index ? (
                          <ul className="table-sub-menu">
                            <li
                              onClick={(e) => {
                                spaceCategory(item.id, index, false);
                                setSubMenu(null);
                              }}
                            >
                              일반공간
                            </li>
                            <li
                              onClick={() => {
                                spaceCategory(item.id, index, true);
                                setSubMenu(null);
                              }}
                            >
                              협력공간
                            </li>
                          </ul>
                        ) : null}
                      </td>
                      <td>
                        <span>{item.companyName}</span>
                      </td>
                      <td>{item.managerName}</td>
                      <td>{item.phoneNumber}</td>
                      <td>
                        <div>
                          <Link onClick={() => delSpace(item.id)}>삭제</Link>
                        </div>
                      </td>
                      <td>
                        <Link to={`/admin/space/detail/${item.id}`} state={{ id: item.id }}>
                          <img src="\img\see-more-btn-plus.svg" />
                          <span>보기</span>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="manage-table-pagenation-container">
              <div className="manage-table-pagenation">
                <a
                  onClick={() => {
                    if (currentPage <= 1) {
                      return;
                    } else {
                      navigate(`/admin/space/${currentPage - 1}?keyword=${searchKeyword}`);
                    }
                  }}
                >
                  <img src="\img\chevron-left.svg" />
                </a>
                {pageIndex.slice(startIndex, startIndex + 4).map((item, index) => {
                  return (
                    <a
                      key={index}
                      className={currentPage === item ? "pagenation-focus" : null}
                      onClick={() => {
                        navigate(`/admin/space/${item}?keyword=${searchKeyword}`);
                      }}
                    >
                      {item}
                    </a>
                  );
                })}
                <a
                  onClick={() => {
                    if (currentPage >= pageIndex.length) {
                      return;
                    } else {
                      navigate(`/admin/space/${currentPage + 1}?keyword=${searchKeyword}`);
                    }
                  }}
                >
                  <img src="\img\chevron-right.svg" />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* 삭제 컴포넌트 */}
        <AlertPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          headerTitle={headerTitle}
          content={content}
          setAlertValue={setAlertValue}
          cancelTitle={cancelTitle}
          confirmTitle={confirmTitle}
        />
      </section>
    </div>
  );
};

export default ManageSpace;
