import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../../css/Manage.css";
import "../../css/space/ManageSpaceReview.css";
import SpaceApi from "../../api/spaceApi";
import SpaceReviewApi from "../../api/spaceReviewApi";
import moment from "moment";
import { toast } from "react-toastify";

const ManageSpaceReview = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [reviewStar, setReviewStar] = useState([]);
  const [reviewScore, setReviewScore] = useState(null);
  const [reviewId, setReviewId] = useState(null);
  const [creatDate, setCreatDate] = useState(null);
  const [reviewInfo, setReviewInfo] = useState({});

  useEffect(() => {
    if (location.state) {
      const { id } = location.state;
      setReviewId(location.state.id);
      getSpaceReview(id);
    }
  }, [location]);

  const getSpaceReview = async (id) => {
    try {
      const info = (await SpaceReviewApi.GetReview(id)).data.data.content;
      let date = moment(info.createdAt).utc().format("YYYY/MM/DD");
      setReviewScore(info.score.toFixed(1));
      setReviewInfo(info);
      setCreatDate(date);

      //별점 채우기
      let star = [];
      for (let i = 0; i < info.score; i++) {
        star.push("/img/star-full.svg");
      }
      for (let i = 0; i < 5 - info.score; i++) {
        star.push("/img/star-empty.svg");
      }
      setReviewStar(star);
    } catch (error) {
      console.log(error);
    }
  };

  const reviewActivation = async () => {
    let obj = {
      activation: false,
    };
    try {
      await SpaceReviewApi.PostActivation(reviewId, obj);
      toast("숨겼습니다.");
    } catch (error) {}
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-space-review">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
          <div>
            <Link onClick={reviewActivation}>숨김</Link>
          </div>
        </div>
        <div className="Manage-form manage-form-gathering-review-see-more">
          <span>리뷰</span>
          <div className="Manage-form-view-details space-review-detail">
            <span>이메일</span>
            <span>{reviewInfo.member ? reviewInfo.member.email : null}</span>
          </div>
          <div className="Manage-form-view-details space-review-detail">
            <span>별명</span>
            <span>{reviewInfo.member ? reviewInfo.member.nickname : null}</span>
          </div>
          <div className="Manage-form-view-details space-review-detail">
            <span>작성일자</span>
            <span>{creatDate}</span>
          </div>
          <div className="gathering-review-detail-img-container">
            {reviewInfo.spaceReviewFiles &&
              reviewInfo.spaceReviewFiles.map((item, index) => {
                return <img src={item.imageUrl} />;
              })}
          </div>
          <div className="gathering-review-contents-container">
            <div className="gathering-review-scope-container">
              {reviewStar.map((item, index) => {
                return <img src={item} />;
              })}
              <span>리뷰{reviewScore}</span>
            </div>
            <span>{reviewInfo.content}</span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageSpaceReview;
