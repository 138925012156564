import api from "./api";

export default class MarkerApi {
  static async Get() {
    return await api.get(`marker`);
  }

  static async Patch(id, data) {
    return await api.patch(`marker/${id}`, data, "", {
      "Content-Type": "multipart/form-data",
    });
  }
}
