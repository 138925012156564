import api from "./api";

export default class MeetingReviewApi {
  static async Get(data) {
    return await api.get(`meeting-program-review`, data);
  }

  static async GetReview(id) {
    return await api.get(`meeting-program-review/${id}`);
  }

  static async Activation(id, data) {
    return await api.post(`meeting-program-review/activation/${id}`, data);
  }
}
