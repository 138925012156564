import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../css/catagory/ManageCatagoryAdit.css";
import "../../css/Manage.css";
import FaqApi from "../../api/faqApi";
import { toast } from "react-toastify";

const ManageCatagoryAdit = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [catagoryId, setCatagoryId] = useState(null);
  const [faqCategory, setFaqCategory] = useState({
    title: null,
  });

  useEffect(() => {
    if (location.state) {
      const { id } = location.state;
      setCatagoryId(location.state.id);
      getFaqCatagory(id);
    }
  }, [location]);

  const getFaqCatagory = async (id) => {
    try {
      const content = (await FaqApi.GetFaqCategory(id)).data.data.content;
      setFaqCategory({
        ...faqCategory,
        title: content.title,
      });
    } catch (err) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const faqCategoryHandler = async () => {
    try {
      await FaqApi.PatchFaqCategory(location.state.id, faqCategory);
      toast("저장되었습니다.");
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const catagoryFalse = async () => {
    let obj = {
      activation: false,
    };
    try {
      await FaqApi.postCategoryActivation(catagoryId, obj);
      toast("카테고리를 숨겼습니다.");
      navigate(-1);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-catagory-adit">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
          <div>
            <a
              onClick={() => {
                catagoryFalse();
              }}
            >
              숨김
            </a>
          </div>
        </div>
        <div className="Manage-form manage-form-catagory-adit">
          <span>카테고리</span>
          <div className="Manage-input-container">
            <p className="Manage-input-title">카테고리</p>
            <input
              className="Manage-input-textarea manage-form-catagory-adit-input"
              type="text"
              placeholder="카테고리를 입력하세요"
              value={faqCategory.title}
              onChange={(e) => {
                setFaqCategory({
                  ...faqCategory,
                  title: e.target.value,
                });
              }}
            />
          </div>
          <div className="Manage-form-save manage-form-save-catagory-adit">
            <button className="cursorPointer" onClick={faqCategoryHandler}>
              저장하기
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageCatagoryAdit;
