import React, { useState } from "react";
import "../../css/Manage.css";
import "../../css/program/ManageProgramAddManager.css";
import ManageProgramAddRootAdmin from "./ManageProgramAddRootAdmin";
import ManageProgramAddManager from "./ManageProgramAddManager";
const ManageProgramAdd = () => {
  return (
    <div className="Manage-container">
      <ManageProgramAddRootAdmin />
      <ManageProgramAddManager />
    </div>
  );
};

export default ManageProgramAdd;
