import { Link, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../../css/popup/ManagePopupsAdit.css";
import "../../css/Manage.css";
import PopupApi from "../../api/popupApi";
import moment from "moment";
import { toast } from "react-toastify";

const ManagePopupsAdit = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [popupInfo, setPopupInfo] = useState({});

  useEffect(() => {
    if (location.state) {
      const { id } = location.state;
      getPopupInfo(id);
    }
  }, [location]);

  const getPopupInfo = async (id) => {
    try {
      const getPopup = (await PopupApi.GetPopup(id)).data.data.content;
      getPopup.startDate = moment(getPopup.startDate).utc().format("YYYY-MM-DD");
      getPopup.endDate = moment(getPopup.endDate).utc().format("YYYY-MM-DD");
      setPopupInfo(getPopup);
    } catch (error) {
      console.log(error);
    }
  };

  const saveImgFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setPopupInfo({
        ...popupInfo,
        binaryUrl: file,
        imageUrl: e.target.result,
        imageDeleteFlag: true,
      });
    };
    reader.readAsDataURL(file);
    e.target.value = "";
  };

  const deleteImage = (e) => {
    setPopupInfo({
      ...popupInfo,
      imageUrl: "",
      imageDeleteFlag: true,
    });
  };
  const popupEditHandler = async () => {
    try {
      const formData = new FormData();
      formData.append("title", popupInfo.title);
      formData.append("startDate", popupInfo.startDate);
      formData.append("endDate", popupInfo.endDate);
      if (popupInfo.binaryUrl) {
        formData.append("image", popupInfo.binaryUrl);
      }
      formData.append("link", popupInfo.link);
      await PopupApi.PatchPopup(location.state.id, formData);
      toast("수정되었습니다.");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div
          className="go-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <Link>
            <img src="\img\chevron-left.svg" />
            <span>뒤로가기</span>
          </Link>
        </div>
        <div className="Manage-form manage-form-popup-adit">
          <span>수정하기</span>
          <div className="Manage-input-container">
            <p className="Manage-input-title">제목</p>
            <input
              type="text"
              className="Manage-input-textarea"
              value={popupInfo.title}
              onChange={(e) => {
                setPopupInfo({
                  ...popupInfo,
                  title: e.target.value,
                });
              }}
            />
          </div>
          <div className="Manage-input-container">
            <p className="Manage-input-title">시작일</p>
            <input
              type="date"
              data-placeholder="0000-00-00"
              required
              aria-required="true"
              className="Manage-input-textarea"
              value={popupInfo.startDate}
              onChange={(e) => {
                setPopupInfo({
                  ...popupInfo,
                  startDate: e.target.value,
                });
              }}
            />
          </div>
          <div className="Manage-input-container">
            <p className="Manage-input-title">종료일</p>
            <input
              type="date"
              data-placeholder="0000-00-00"
              required
              aria-required="true"
              className="Manage-input-textarea"
              value={popupInfo.endDate}
              onChange={(e) => {
                setPopupInfo({
                  ...popupInfo,
                  endDate: e.target.value,
                });
              }}
            />
          </div>
          <div className="Manage-img-container">
            <p className="Manage-input-title">사진업로드</p>
            <div>
              <div className="notice-input-img-container">
                <label htmlFor="img-input">
                  <img src="\img\img-registration.svg" />
                  <span>이미지 등록</span>
                </label>
                <input
                  id="img-input"
                  className="Manage-input-textarea"
                  type="file"
                  required
                  accept="image/*"
                  onChange={(e) => {
                    saveImgFile(e);
                  }}
                />
                {popupInfo.imageUrl ? (
                  <div className="notice-img uploaded-img-container">
                    <img className="uploaded-img" src={popupInfo?.imageUrl} />
                    <img
                      src="\img\image-cancel.svg"
                      onClick={(e) => deleteImage(e)}
                    />
                  </div>
                ) : null}
              </div>
              <span>*권장사이즈 96*118</span>
            </div>
          </div>
          <div className="Manage-input-container">
            <p className="Manage-input-title">연결 링크</p>
            <input
              type="url"
              className="Manage-input-textarea"
              value={popupInfo.link}
              onChange={(e) => {
                setPopupInfo({
                  ...popupInfo,
                  link: e.target.value,
                });
              }}
            />
          </div>
          <div className="Manage-form-save Manage-form-save-banner">
            <button className="cursorPointer" onClick={popupEditHandler}>
              저장하기
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManagePopupsAdit;
