import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import "../../css/gathering/ManageGatheringSchedule.css";
import "../../css/Manage.css";
import { useEffect } from "react";
import MeetingApi from "../../api/meetingApi";
import { nullCheck } from "../../utils/check";
import "moment/locale/ko";
import moment from "moment";
import { toast } from "react-toastify";
import AlertPopup from "../../components/AlertPopup";
import { downloadFile } from "../../utils/download";

const ManageGatheringSchedule = () => {
  let navigate = useNavigate();
  const params = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [alertValue, setAlertValue] = useState("");

  const [meetingId, setMeetingId] = useState(0);

  useEffect(() => {
    if (alertValue) {
      for (let index = 0; index < meetingSchedules.length; index++) {
        const element = meetingSchedules[index];

        if (!nullCheck(element.meetingSchedulePrograms)) {
          for (
            let index = 0;
            index < element.meetingSchedulePrograms.length;
            index++
          ) {
            const element2 = element.meetingSchedulePrograms[index];
            deleteMeetingScheduleProgram(element2.id);
          }
        }
        deleteMeetingSchedule(element.id);
      }
    }
  }, [alertValue]);

  async function deleteMeetingSchedule(id) {
    try {
      await MeetingApi.deleteMeetingSchedule(id);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  }

  async function deleteMeetingScheduleProgram(id) {
    try {
      await MeetingApi.deleteMeetingScheduleProgram(id);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  }

  // mounted
  useEffect(() => {
    getMeetingScheduleInfo();
    setMeetingId(params.id);
  }, []);

  // 일정 목록
  const [meetingSchedules, setMeetingSchedules] = useState([]);

  async function getMeetingScheduleInfo() {
    try {
      const response = await MeetingApi.getMeetingSchedule({
        meetingId: params.id,
      });
      const data = response.data.data.content;

      setMeetingSchedules(
        data.map((item) => {
          return {
            ...item,
            openFlag: false,
          };
        })
      );
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  }

  function getDiff(startTime, endTime) {
    if (nullCheck(startTime) || nullCheck(endTime)) {
      return ''
    }

    let duration = 0
    if (startTime > endTime) {
      duration = moment.duration(
        moment(endTime, "HH:mm").add(24, 'hours').diff(moment(startTime, "HH:mm"))
      );
    } else {
      duration = moment.duration(
        moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm"))
      );
    }

    // 시간과 분으로 변환
    const hours = Math.floor(duration.asHours()); // 시간 단위
    const minutes = duration.minutes();

    if (minutes === 0) {
      return hours + "시간";
    }
    return hours + "시간" + minutes + "분";
  }  

  return (
    <div>
      <section>
        <div className="Manage-form manage-form-gathering-schedule-exist">
          <div className="gathering-schedule-title" style={{ justifyContent: 'unset' }}>
            <span style={{ marginRight: '650px' }}>일정</span>
            <a
              style={{ marginRight: '5px' }}
              onClick={(e) => {
                // if (meetingSchedules.length > 0) {
                //   setIsOpen(true);
                //   return;
                // }
                navigate(
                  `/admin/gathering/detail/schedule/enroll/${meetingId}`
                );
              }}
            >
              일정관리
            </a>
            <a style={{
              width: '65px',
              height: '29px',
              background: '#ffffff',
              border: '1px solid #ef594b',
              borderRadius: '4px',
              display: 'flex',
              boxSizing: 'border-box',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '23px',
              textAlign: 'center',
              color: '#ef594b',
            }}
              onClick={(e) => {
                if (meetingSchedules.length > 0) {
                  setIsOpen(true);
                  return;
                }
              }}
            >
              삭제
            </a>
          </div>

          {meetingSchedules.length <= 0 ? (
            <div
              style={{
                height: "800px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Pretendard",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "18px",
                lineHeight: "25px",
                color: "#808991",
              }}
            >
              <span>일정을 추가하세요</span>
            </div>
          ) : null}

          {meetingSchedules.map((item, index) => {
            return (
              <div className="schedule-info-exist" key={index}>
                <div className="schedule-date-info">
                  <span>
                    {index + 1}일차{" "}
                    {moment(new Date(item.openDate)).utc().format("yyyy-MM-DD")}{" "}
                    (
                    {moment(new Date(item.openDate)).utc()
                      .format("dddd", "ko")
                      .substring(0, 1)}
                    )
                  </span>
                </div>

                <div
                  className="gathering-schedule-info"
                  onClick={() => {
                    item.openFlag = !item.openFlag;
                    setMeetingSchedules([...meetingSchedules]);
                    // if (!item.openFlag) {
                    //   item.openFlag = !item.openFlag
                    //   setMeetingSchedules([...meetingSchedules])
                    // }
                  }}
                >
                  <div className="gathering-schedule-info-detail">
                    <span>일정명</span>
                    <span>{item.title}</span>
                  </div>
                  <img
                    className="gathering-schedule-info-toggle-btn"
                    style={{ cursor: "pointer" }}
                    src={
                      item.openFlag
                        ? "/img/table-toggle-up.svg"
                        : "/img/table-togle.svg"
                    }
                  />
                  {!item.openFlag ? null : (
                    <div>
                      <div className="gathering-schedule-info-detail">
                        <span>일정종류</span>
                        <span>장소방문</span>
                      </div>
                      <div className="gathering-schedule-info-detail">
                        <span>시간</span>
                        <span>
                          {item.startTime} ~ {item.endTime} (
                          {getDiff(item.startTime, item.endTime)})
                        </span>
                      </div>
                      <div className="gathering-schedule-info-detail">
                        <span>이동수단</span>
                        <span>{item.vehicle}</span>
                      </div>
                      <div className="gathering-schedule-info-detail gathering-schedule-info-flex-start">
                        <span>소개</span>
                        <span>{item.content}</span>
                      </div>
                      <div className="gathering-schedule-info-detail gathering-schedule-info-flex-start">
                        <span>
                          상세이미지
                          <br />
                          (*최대 5장)
                        </span>
                        <div
                          style={{
                            flexWrap: "wrap",
                            width: "500px",
                          }}
                        >
                          {item.meetingScheduleFiles?.map(
                            (image, meetingScheduleProgramIamgeIndex) => {
                              return (
                                <div>
                                  <img
                                    src={image.imageUrl}
                                    style={{
                                      width: "90px",
                                      height: "90px",
                                      marginTop: "6px",
                                      marginLeft: "6px",
                                    }}
                                  />
                                  <div className="schedule-info-detail-img-name" >
                                    <span>{image.imageOriginFileName}</span>
                                    <img src="\img\download_black.svg" />
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {item.meetingSchedulePrograms.map((item2, index2) => {
                  return (
                    <div
                      className="gathering-schedule-info"
                      onClick={() => {
                        if (nullCheck(item2.openFlag)) {
                          item2.openFlag = true;
                          setMeetingSchedules([...meetingSchedules]);
                        } else {
                          item2.openFlag = !item2.openFlag;
                          setMeetingSchedules([...meetingSchedules]);
                        }
                      }}
                    >
                      <div className="gathering-schedule-info-detail">
                        <span>일정명</span>
                        <span>{item2.title}</span>
                      </div>
                      <img
                        className="gathering-schedule-info-toggle-btn"
                        src={
                          item2.openFlag
                            ? "/img/table-toggle-up.svg"
                            : "/img/table-togle.svg"
                        }
                      />
                      {!item2.openFlag ? null : (
                        <div>
                          <div className="gathering-schedule-info-detail">
                            <span>일정종류</span>
                            <span>
                              {item2.vehicle ? "장소방문" : "프로그램"}
                            </span>
                          </div>
                          <div className="gathering-schedule-info-detail">
                            <span>시간</span>
                            <span>
                              {item2.startTime} ~ {item2.endTime} (
                              {getDiff(item2.startTime, item2.endTime)})
                            </span>
                          </div>
                          {item2.vehicle ? (
                            <div className="gathering-schedule-info-detail">
                              <span>이동수단</span>
                              <span>{item2.vehicle}</span>
                            </div>
                          ) : null}
                          <div className="gathering-schedule-info-detail gathering-schedule-info-flex-start">
                            <span>소개</span>
                            <span>{item2.content}</span>
                          </div>
                          <div className="gathering-schedule-info-detail gathering-schedule-info-flex-start">
                            <span>
                              상세이미지
                              <br />
                              (*최대 5장)
                            </span>
                            <div
                              style={{
                                flexWrap: "wrap",
                                width: "500px",
                              }}
                            >
                              {item2.meetingScheduleProgramFiles?.map(
                                (image, meetingScheduleProgramIamgeIndex) => {
                                  return (
                                    <div>
                                      <img
                                        src={image.imageUrl}
                                        style={{
                                          width: "90px",
                                          height: "90px",
                                          marginTop: "6px",
                                          marginLeft: "6px",
                                        }}
                                      />
                                      <div className="schedule-info-detail-img-name">
                                        <span>{image.imageOriginFileName}</span>
                                        <img src="\img\download_black.svg" />
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </section>
      <AlertPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        headerTitle={"일정관리"}
        content={`삭제된 데이터는 복구가 불가능합니다.  삭제 하시겠습니까?`}
        cancelTitle={"취소"}
        confirmTitle={"확인"}
        setAlertValue={setAlertValue}
      ></AlertPopup>
    </div>
  );
};

export default ManageGatheringSchedule;
