import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import "../../css/community/ManageCommunity.css";
import "../../css/Manage.css";
import CommunityApi from "../../api/communityApi";
import moment from "moment";

const ManageCommunity = () => {
  let navigate = useNavigate();
  const { page } = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [communityList, setCommunityList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(null);

  useEffect(() => {
    setSearchKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    setCurrentPage(Number(page));
    getCommunityList();
  }, [currentPage, location]);

  const getCommunityList = async () => {
    try {
      const communityInfo = (
        await CommunityApi.Get({
          page: currentPage,
          size: 10,
          keyword: searchParams.get("keyword"),
        })
      ).data.data;
      setCommunityList(communityInfo.content);
      setTotalCount(communityInfo.totalCount);

      setPostsPerPage(communityInfo.content.length);

      const count = Math.ceil(communityInfo.totalCount / communityInfo.content.length / 4);

      for (let i = 1; i <= count; i++) {
        if (currentPage / 4 <= i) {
          setStartIndex(4 * (i - 1));
          return;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let page = Math.ceil(totalCount / postsPerPage);
    let arr = [];
    for (var i = 1; i <= page; i++) {
      arr.push(i);
    }
    setPageIndex(arr);
  }, [totalCount]);

  return (
    <div className="Manage-container">
      <section className="Manage-contents Manage-contents-table">
        <span>커뮤니티 관리</span>
        <div className="manage-table-container">
          <div className="Manage-community-search-container">
            <div className="Manage-community-search-number">
              <span>전체</span>
              <span>{totalCount}</span>
            </div>
            <div className="Manage-community-search">
              <div style={{ position: "relative" }}>
                <input
                  className="table-search-input"
                  type="text"
                  placeholder="검색어를 입력하세요"
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                  }}
                  value={searchKeyword}
                />
                <img
                  src="/img/search.svg"
                  alt=""
                  style={{
                    position: "absolute",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/admin/community/1?keyword=${searchKeyword}`);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="Manage-table manage-table-community">
            <table>
              <thead>
                <tr>
                  <th>제목</th>
                  <th>이메일</th>
                  <th>등록일자</th>
                  <th>숨김</th>
                </tr>
              </thead>
              <tbody>
                {communityList.map((item, index) => {
                  let createDate = moment(item.createdAt).utc().format("YYYY/MM/DD");
                  return (
                    <tr
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(`/admin/community/detail/${item.id}`, {
                          state: { id: item.id },
                        });
                      }}
                    >
                      <td>{item.title}</td>
                      <td>{item.member.email}</td>
                      <td>{createDate}</td>
                      <td>
                        <a>숨김</a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="manage-table-pagenation-container">
              <div className="manage-table-pagenation">
                <a
                  onClick={() => {
                    if (currentPage <= 1) {
                      return;
                    } else {
                      navigate(`/admin/community/${currentPage - 1}?keyword=${searchKeyword}`);
                    }
                  }}
                >
                  <img src="\img\chevron-left.svg" />
                </a>
                {pageIndex.slice(startIndex, startIndex + 4).map((item, index) => {
                  return (
                    <a
                      key={index}
                      className={currentPage === item ? "pagenation-focus" : null}
                      onClick={() => {
                        navigate(`/admin/community/${item}?keyword=${searchKeyword}`);
                      }}
                    >
                      {item}
                    </a>
                  );
                })}
                <a
                  onClick={() => {
                    if (currentPage >= pageIndex.length) {
                      return;
                    } else {
                      navigate(`/admin/community/${currentPage + 1}?keyword=${searchKeyword}`);
                    }
                  }}
                >
                  <img src="\img\chevron-right.svg" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageCommunity;
