import api from "./api";

export default class TermsApi {
  static async Get(data) {
    return await api.get(`terms`, data);
  }
  static async GetTerms(id) {
    return await api.get(`terms/${id}`);
  }
  static async Post(data) {
    return await api.post(`terms`, data);
  }
  static async Patch(id, data) {
    return await api.patch(`terms/${id}`, data);
  }
}
