import React, { useEffect, useState } from "react";
import "../../css/Manage.css";
import "../../css/gathering/ManageGatheringInquirySeeMore.css";
import moment from "moment";
import { Link } from "react-router-dom";
import MeetingAskApi from "../../api/meetingAskApi";
import { toast } from "react-toastify";

const ManageGatheringInquirySeeMore = (props) => {
  const [askDate, setAskDate] = useState(null);
  const [askDetail, setAskDetail] = useState({});
  useEffect(() => {
    let date = moment(props.askInfo[props.selectedIndex].createdAt).utc().format("YYYY/MM/DD");
    setAskDate(date);
    setAskDetail(props.askInfo[props.selectedIndex]);
  }, []);

  const editAnswer = async () => {    
    let obj = {
      answer: askDetail.answer,
    };
    try {
      await MeetingAskApi.Patch(askDetail.id, obj);
      toast("답변을 완료했습니다.");
      props.setPopup(false)
      props.getAsk()
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };
  return (
    <div>
      <div className="gathering-inquiry-seemore-container">
        <div className="gathering-inquiry-seemore-popup" ref={props.searchRef}>
          <span>문의 상세</span>
          <div className="Manage-form-view-details gathering-inquiry-seemore-detail">
            <span>문의자 이메일</span>
            <span>{askDetail.member && askDetail.member.email}</span>
          </div>
          <div className="Manage-form-view-details gathering-inquiry-seemore-detail">
            <span>작성일자</span>
            <span>{askDate}</span>
          </div>
          <div className="Manage-form-view-details gathering-inquiry-seemore-detail">
            <span>상태</span>
            <span
              className={
                askDetail.status
                  ? "table-complete-answer"
                  : "table-waiting-answer"
              }
            >
              {askDetail.status ? "답변완료" : "답변대기"}
            </span>
          </div>
          <div className="Manage-form-view-details gathering-inquiry-seemore-detail manage-flex-start">
            <span>문의 내용</span>
            <span>{askDetail.question}</span>
          </div>
          {askDetail.status ? (
            <div className="Manage-form-view-details gathering-inquiry-seemore-detail manage-flex-start">
              <span>답변 내용</span>
              <div className="answer-true-contanier">
                <span>{askDetail.answer}</span>
                <button
                  className="editAnswer-btn cursorPointer"
                  onClick={() => {
                    setAskDetail((askDetail) => ({
                      ...askDetail,
                      status: false,
                    }));
                  }}
                >
                  수정하기
                </button>
              </div>
            </div>
          ) : (
            <div className="gathering-inquiry-seemore-detail">
              <span className="answer">답변</span>
              <div>
                <textarea
                  placeholder="내용을 입력하세요"
                  value={askDetail.answer}
                  onChange={(e) => {
                    setAskDetail((askDetail) => ({
                      ...askDetail,
                      answer: e.target.value,
                    }));
                  }}
                ></textarea>
                <Link onClick={editAnswer}>답변하기</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageGatheringInquirySeeMore;
