import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../css/Manage.css";
import "../../css/program/ManageProgramAddRootAdmin.css";
import SpaceApi from "../../api/spaceApi";
import ProgramApi from "../../api/programApi";
import { toast } from "react-toastify";
import { nullCheck } from "../../utils/check";

const ManageProgramAddRootAdmin = () => {
  let navigate = useNavigate();

  const [programMenu, setProgramMenu] = useState([
    { category: "ACTIVITY", name: "액티비티" },
    { category: "CULTURE", name: "문화,예술" },
    { category: "COOK", name: "요리,DIY" },
    { category: "SELF", name: "자기계발" },
    { category: "HOBBY", name: "취미활동" },
    { category: "HEALING", name: "힐링" },
    { category: "ETC", name: "기타" },
  ]);
  const [selectMenuText, setSelectMenuText] = useState("카테고리를 선택하세요");
  const [selectMenu, setSelectMenu] = useState(false);
  // const [searchKeyword, setSearchKeyword] = useState(null);
  const [spaceInfo, setSpaceInfo] = useState([]);
  let [programFile, setProgramFile] = useState([]);
  let [programInfo, setProgramInfo] = useState({
    managerId: sessionStorage.getItem("id"),
    spaceId: 0,
    spaceName: "",
    programCategory: null,
    title: null,
    requiredTime: null,
    content: null,
    images: [],
  });

  useEffect(() => {
    const managerAuthority = sessionStorage.getItem("managerAuthority");

    if (managerAuthority === "MANAGER") {
      setSpace();
    }
  }, []);

  const setSpace = async () => {
    try {
      const response = await SpaceApi.Get({
        size: 1,
        managerId: sessionStorage.getItem("id"),
      });
      const data = response.data.data;
      setProgramInfo((programInfo) => ({
        ...programInfo,
        spaceName: data.content[0].name,
        spaceId: data.content[0].id,
      }));
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  useEffect(() => {
    if (programInfo.spaceName) {
      getSpaceList();
    } else {
      setProgramInfo({
        ...programInfo,
        spaceId: 0,
      });
    }
  }, [programInfo.spaceName]);

  const getSpaceList = async () => {
    try {
      const response = await SpaceApi.Get({
        size: 1000,
        keyword: programInfo.spaceName,
      });
      const data = response.data.data;
      setSpaceInfo(
        data.content.map((item) => {
          return {
            ...item,
          };
        })
      );
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const programImgs = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      if (programFile.length === 5) {
        toast("이미지는 최대 5장까지 등록가능합니다.");
        return;
      }
      const obj = {};
      obj.image = file;
      obj.imageUrl = e.target.result;
      programFile.push(obj);
      setProgramFile([...programFile]);
      setProgramInfo({
        ...programInfo,
        images: [...programFile],
      });
    };
    reader.readAsDataURL(file);
    e.target.value = "";
  };

  const deleteImages = (index) => {
    // let copyFile = [...programFile];
    // programFile.splice(index, 1);
    programFile = programFile.filter((file, currentIndex) => currentIndex !== index);
    setProgramFile([...programFile]);
    // let copyData = [...programInfo.images];
    programInfo.images = programInfo.images.filter((file, currentIndex) => currentIndex !== index);
    setProgramInfo((programInfo) => ({
      ...programInfo,
      images: [...programInfo.images],
    }));
  };

  const programAdd = async () => {
    try {
      if (!programInfo.spaceId) {
        toast("공간명을 입력해주세요.");
        return;
      }
      if (!programInfo.programCategory) {
        toast("프로그램 카테고리를 입력해주세요.");
        return;
      }
      if (!programInfo.title) {
        toast("프로그램명을 입력해주세요.");
        return;
      }
      if (!programInfo.requiredTime) {
        toast("소요시간을 입력해주세요.");
        return;
      }
      if (!programInfo.content) {
        toast("상세설명을 입력해주세요.");
        return;
      }

      const formData = new FormData();
      formData.append("managerId", programInfo.managerId);
      formData.append("spaceId", programInfo.spaceId);
      formData.append("programCategory", programInfo.programCategory);
      formData.append("title", programInfo.title);
      formData.append("requiredTime", programInfo.requiredTime);
      formData.append("content", programInfo.content);
      if (programInfo.images.length) {
        for (let i = 0; i < programInfo.images.length; i++) {
          formData.append(`images`, programInfo.images[i].image);
        }
      }
      await ProgramApi.Post(formData);
      toast("추가되었습니다.");
      navigate("/admin/program/1");
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-program-seemore">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
        </div>
        <div className="Manage-form manage-form-program-see-more">
          <span>추가하기</span>
          {sessionStorage.getItem("managerAuthority") === "MANAGER" ? null : (
            <div className="Manage-input-container Manage-input-container-program-seemore">
              <span className="Manage-input-title ">공간명*</span>
              <div className="space-search-container">
                <input
                  className="Manage-input-textarea manage-form-program-add-input"
                  type="text"
                  placeholder="공간명 검색 후 선택하세요"
                  required
                  value={programInfo.spaceName}
                  onChange={(e) => {
                    programInfo.spaceName = e.target.value;

                    setProgramInfo({
                      ...programInfo,
                    });
                  }}
                />
                {programInfo.spaceName.length > 0 && programInfo.spaceId === 0 ? (
                  <div className="space-sub-menu">
                    {spaceInfo.map((item, index) => (
                      <div
                        key={index}
                        className="option cursorPointer"
                        onClick={() => {
                          setProgramInfo((programInfo) => ({
                            ...programInfo,
                            spaceId: item.id,
                            spaceName: item.name,
                          }));
                        }}
                      >
                        {item.name}
                      </div>
                    ))}
                  </div>
                ) : // spaceInfo.map((item, index) => <div className="space-sub-menu">
                //   <div
                //     key={index}
                //     className="option cursorPointer"
                //     onClick={() => {
                //       setProgramInfo((programInfo) => ({
                //         ...programInfo,
                //         spaceId: item.id,
                //         spaceName: item.name,
                //       }));
                //     }}>
                //     {item.name}
                //   </div>
                // </div>)
                null}
              </div>
            </div>
          )}

          <div className="Manage-input-container Manage-input-container-program-seemore">
            <p className="Manage-input-title">프로그램 카테고리*</p>
            <div className="select">
              <div className="selected select-category" onClick={() => setSelectMenu(!selectMenu)}>
                <div className={selectMenuText === "카테고리를 선택하세요" ? "selected-value-basic" : "selected-value"}>
                  {!selectMenuText ? "카테고리를 선택하세요" : selectMenuText}
                </div>
                <div className="select-toggle-btn">
                  <img src="\img\table-togle.svg" />
                </div>
              </div>
              {selectMenu ? (
                <ul className="option-container opt-con-faq-add">
                  {programMenu.map((item, index) => {
                    return (
                      <li
                        className="option"
                        onClick={() => {
                          setSelectMenuText(item.name);
                          setProgramInfo((programInfo) => ({
                            ...programInfo,
                            programCategory: item.category,
                          }));
                          setSelectMenu(false);
                        }}
                      >
                        {item.name}
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </div>
          </div>
          <div className="Manage-input-container Manage-input-container-program-seemore">
            <span className="Manage-input-title">프로그램명*</span>
            <input
              className="Manage-input-textarea manage-form-program-add-input"
              type="text"
              placeholder="프로그램명"
              required
              onChange={(e) => {
                setProgramInfo((programInfo) => ({
                  ...programInfo,
                  title: e.target.value,
                }));
              }}
            />
          </div>
          <div className="Manage-input-container Manage-input-container-program-seemore">
            <span className="Manage-input-title">소요시간*</span>
            <div className="taken-time-container">
              <input
                className="Manage-input-textarea manage-form-program-add-input"
                type="number"
                required
                onChange={(e) => {
                  setProgramInfo((programInfo) => ({
                    ...programInfo,
                    requiredTime: e.target.value,
                  }));
                }}
              />
              <span className="taken-time-placeholder">시간</span>
            </div>
          </div>
          <div className="Manage-input-container Manage-input-container-program-seemore">
            <span className="Manage-input-title">상세설명*</span>
            <textarea
              placeholder="상세설명을 입력하세요"
              onChange={(e) => {
                setProgramInfo((programInfo) => ({
                  ...programInfo,
                  content: e.target.value,
                }));
              }}
            ></textarea>
          </div>
          <div className="Manage-input-container Manage-input-container-program-seemore">
            <span className="Manage-input-title">이미지 (*최대 5장)</span>
            <div className="manage-form-program-add-input-img manage-form-program-add-input-img-root">
              <label htmlFor="img-input">
                <img src="\img\img-registration.svg" />
                <span>이미지 등록</span>
              </label>
              <input
                id="img-input"
                className="Manage-input-textarea manage-form-program-add-input"
                type="file"
                required
                onChange={(e) => {
                  programImgs(e);
                }}
              />
              {programInfo.images &&
                programInfo.images.map((item, index) => {
                  return (
                    <div className="space-img uploaded-imgs" key={index}>
                      <img className="uploaded-img" src={item.imageUrl} />
                      <img
                        className="space-img-cancel cursorPointer"
                        src="\img\image-cancel.svg"
                        onClick={(e) => deleteImages(index)}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="Manage-form-save manage-form-save-program-add">
            <button className="cursorPointer" onClick={programAdd}>
              저장하기
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageProgramAddRootAdmin;
