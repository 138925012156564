import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../../css/gathering/ManageGatheringReview.css";
import "../../css/Manage.css";
import MeetingReviewApi from "../../api/meetingReview";
import moment from "moment";
import { toast } from "react-toastify";
const ManageGatheringReview = (props) => {
  let navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { id, page } = params;
  const [activation, setActivation] = useState(null);
  const [reviewList, setReviewList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [visible, setvisible] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(null);

  useEffect(() => {
    setCurrentPage(page ? Number(page) : 1);
    getProgramReview();
  }, [currentPage, location]);

  const getProgramReview = async () => {
    try {
      const list = (
        await MeetingReviewApi.Get({
          page: currentPage,
          size: 8,
          meetingId: props.meetingId,
        })
      ).data.data;

      setReviewList(list.content);
      setTotalCount(list.totalCount);
      setPostsPerPage(list.content.length);

      const count = Math.ceil(list.totalCount / list.content.length / 4);

      for (let i = 1; i <= count; i++) {
        if (currentPage / 4 <= i) {
          setStartIndex(4 * (i - 1));
          console.log();
          return;
        }
      }
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  useEffect(() => {
    let page = Math.ceil(totalCount / postsPerPage);
    let arr = [];
    for (var i = 1; i <= page; i++) {
      arr.push(i);
    }
    setPageIndex(arr);
  }, [totalCount]);

  useEffect(() => {}, [activation]);

  const activationReview = async (id, state) => {
    let obj = {
      activation: state,
    };
    try {
      await MeetingReviewApi.Activation(id, obj);
      toast("수정되었습니다.");
      getProgramReview();
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div>
      <section>
        <div className="Manage-form manage-form-gathering-review">
          <div className="gathering-review-title">
            <span>프로그램 리뷰</span>
            <span>{totalCount}개</span>
          </div>
          <table className="gathering-review-table">
            <thead>
              <tr>
                <th>이메일</th>
                <th>별명</th>
                <th>작성일자</th>
                <th
                  style={{
                    width: "30px",
                  }}
                >
                  평점
                </th>
                <th>리뷰상태</th>
                <th>상세보기</th>
              </tr>
            </thead>
            <tbody>
              {reviewList.map((item, index) => {
                let createDate = moment(item.createdAt).utc().format("YYYY/MM/DD");
                return (
                  <tr key={index}>
                    <td>{item.member.email}</td>
                    <td>
                      <span>{item.member.nickname}</span>
                    </td>
                    <td>{createDate}</td>
                    <td>{item.score}.0</td>
                    <td className="table-sub-catagory-container">
                      <a
                        onClick={() => {
                          if (visible === index) {
                            setvisible(null);
                          } else {
                            setvisible(index);
                          }
                        }}
                      >
                        <span>{item.activation ? "일반" : "숨김"}</span>
                        <img src="\img\table-togle.svg" />
                      </a>
                      {visible === index ? (
                        <ul className="table-sub-menu">
                          <li
                            onClick={() => {
                              activationReview(item.id, true);
                              setvisible(null);
                            }}
                          >
                            일반
                          </li>
                          <li
                            onClick={() => {
                              activationReview(item.id, false);
                              setvisible(null);
                            }}
                          >
                            숨김
                          </li>
                        </ul>
                      ) : null}
                    </td>
                    <td>
                      <Link to={`/admin/gathering/detail/review/detail/${item.id}`} state={{ id: item.id }}>
                        <img src="\img\see-more-btn-plus.svg" />
                        <span>보기</span>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="manage-table-pagenation-container">
            <div className="manage-table-pagenation">
              <a
                onClick={() => {
                  if (currentPage <= 1) {
                    return;
                  } else {
                    navigate(`/admin/gathering/detail/${id}/${currentPage - 1}`, {
                      key: 3,
                      state: {
                        id: "프로그램 리뷰",
                      },
                    });
                  }
                }}
              >
                <img src="\img\chevron-left.svg" />
              </a>
              {pageIndex.slice(startIndex, startIndex + 4).map((item, index) => {
                return (
                  <a
                    key={index}
                    className={currentPage === item ? "pagenation-focus" : null}
                    onClick={() => {
                      navigate(`/admin/gathering/detail/${id}/${item}`, {
                        key: 3,
                        state: {
                          id: "프로그램 리뷰",
                        },
                      });
                    }}
                  >
                    {item}
                  </a>
                );
              })}
              <a
                onClick={() => {
                  if (currentPage >= pageIndex.length) {
                    return;
                  } else {
                    navigate(`/admin/gathering/detail/${id}/${currentPage + 1}`, {
                      key: 3,
                      state: {
                        id: "프로그램 리뷰",
                      },
                    });
                  }
                }}
              >
                <img src="\img\chevron-right.svg" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageGatheringReview;
