import React, { useEffect, useState } from "react";
import "../css/Sidebar.css";
import { Link } from "react-router-dom";
import ManagerApi from "../api/managerApi";

const Sidebar = () => {
  const [openHomeMenu, setOpenHomeMenu] = useState(() => {
    if (window.location.pathname.includes("/admin/home")) {
      return 0;
    }
    if (
      window.location.pathname.includes("/admin/member") ||
      window.location.pathname.includes("/admin/space") ||
      window.location.pathname.includes("/admin/spacesupport") ||
      window.location.pathname.includes("/admin/withdraw")
    ) {
      return 1;
    }
    if (
      window.location.pathname.includes("/admin/gathering") ||
      window.location.pathname.includes("/admin/program") ||
      window.location.pathname.includes("/admin/mileage")
    ) {
      return 2;
    }
    if (window.location.pathname.includes("/admin/community") || window.location.pathname.includes("/admin/report")) {
      return 3;
    }
    if (
      window.location.pathname.includes("/admin/alarm") ||
      window.location.pathname.includes("/admin/notice") ||
      window.location.pathname.includes("/admin/faq") ||
      window.location.pathname.includes("/admin/popup") ||
      window.location.pathname.includes("/admin/terms") ||
      window.location.pathname.includes("/admin/banner") ||
      window.location.pathname.includes("/admin/marker") ||
      window.location.pathname.includes("/admin/opensource")
    ) {
      return 4;
    }
    if (
      window.location.pathname.includes("/admin/permission") ||
      window.location.pathname.includes("/admin/information")
    ) {
      return 5;
    }
  });
  const [managerEmail, setManagerEmail] = useState(null);
  const [accessAuthority, setAccessAuthority] = useState([]);
  const [authority, setAuthority] = useState(null);

  useEffect(() => {
    const managerId = Number(sessionStorage.getItem("id"));
    setAuthority(sessionStorage.getItem("managerAuthority"));
    getManager(managerId);
  }, []);

  const getManager = async (id) => {
    try {
      const info = (await ManagerApi.Get(id)).data.data.content;
      let modifiedString = info.accessMenu.replace(/([a-zA-Z-]+)/g, '"$1"');
      let accessTemp = JSON.parse(modifiedString);
      setAccessAuthority(accessTemp);
      setManagerEmail(info.name);
      // console.log(authority);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="admin-sidebar">
      <div className="sidebar-admin">
        <img src="/img/sidebar-profile-img.svg"></img>
        <span>{managerEmail}</span>
      </div>
      <div className="sidebar-category">
        <div className="sidebar-catagory-container">
          <Link
            to={"/admin/home"}
            className={openHomeMenu === 0 ? "openMenuStyle" : ""}
            onClick={() => {
              setOpenHomeMenu(0);
            }}
          >
            홈
          </Link>
        </div>
        <div
          className="sidebar-catagory-container"
          onClick={() => {
            if (openHomeMenu === 1) {
              setOpenHomeMenu(null);
            } else {
              setOpenHomeMenu(1);
            }
          }}
        >
          <span className={openHomeMenu === 1 ? "openMenuStyle" : ""}>사용자 관리</span>
          {openHomeMenu === 1 ? (
            <img className="cursorPointer" src="\img\sidebar-minus-icon.svg" />
          ) : (
            <img className="cursorPointer" src="\img\sidebar-plus-icon.svg" />
          )}
        </div>
        {openHomeMenu === 1 && (
          <div className="sidebar-submenu-wrap">
            {accessAuthority.includes("member") ? (
              <div className="sidebar-submenu">
                <Link
                  to={"/admin/member/1"}
                  className={window.location.pathname.includes("/admin/member") ? "sidebar-submenu-active" : null}
                >
                  일반회원 관리
                </Link>
              </div>
            ) : null}
            {accessAuthority.includes("space-member") ? (
              <div className="sidebar-submenu">
                <Link
                  to={"/admin/space/1"}
                  className={
                    window.location.pathname === "/admin/space" || window.location.pathname.includes("/admin/space/")
                      ? "sidebar-submenu-active"
                      : null
                  }
                >
                  공간 관리
                </Link>
              </div>
            ) : null}
            {accessAuthority.includes("space-support") ? (
              <div className="sidebar-submenu">
                <Link
                  to={"/admin/spacesupport/1"}
                  className={window.location.pathname.includes("/admin/spacesupport") ? "sidebar-submenu-active" : null}
                >
                  공간지원 관리
                </Link>
              </div>
            ) : null}
            {accessAuthority.includes("leave-member") ? (
              <div className="sidebar-submenu">
                <Link
                  to={"/admin/withdraw/1"}
                  className={window.location.pathname.includes("/admin/withdraw") ? "sidebar-submenu-active" : null}
                >
                  탈퇴회원 관리
                </Link>
              </div>
            ) : null}
          </div>
        )}
        <div
          className="sidebar-catagory-container"
          onClick={() => {
            if (openHomeMenu === 2) {
              setOpenHomeMenu(null);
            } else {
              setOpenHomeMenu(2);
            }
          }}
        >
          <span className={openHomeMenu === 2 ? "openMenuStyle" : ""}>운영 관리</span>
          {openHomeMenu === 2 ? (
            <img className="cursorPointer" src="\img\sidebar-minus-icon.svg" />
          ) : (
            <img className="cursorPointer" src="\img\sidebar-plus-icon.svg" />
          )}
        </div>
        {openHomeMenu === 2 && (
          <div className="sidebar-submenu-wrap">
            {accessAuthority.includes("meeting") ? (
              <div className="sidebar-submenu">
                <Link
                  to={"/admin/gathering/1"}
                  className={window.location.pathname.includes("/admin/gathering") ? "sidebar-submenu-active" : null}
                >
                  모임 관리
                </Link>
              </div>
            ) : null}
            {accessAuthority.includes("program") ? (
              <div className="sidebar-submenu">
                <Link
                  to={"/admin/program/1"}
                  className={window.location.pathname.includes("/admin/program") ? "sidebar-submenu-active" : null}
                >
                  프로그램 관리
                </Link>
              </div>
            ) : null}
            {accessAuthority.includes("mileage") ? (
              <div className="sidebar-submenu">
                <Link
                  to={"/admin/mileage/1"}
                  className={window.location.pathname.includes("/admin/mileage") ? "sidebar-submenu-active" : null}
                >
                  마을리지 관리
                </Link>
              </div>
            ) : null}
          </div>
        )}
        {sessionStorage.getItem("managerAuthority") !== "MANAGER" ? (
          <div
            className="sidebar-catagory-container"
            onClick={() => {
              if (openHomeMenu === 3) {
                setOpenHomeMenu(null);
              } else {
                setOpenHomeMenu(3);
              }
            }}
          >
            <span className={openHomeMenu === 3 ? "openMenuStyle" : ""}>커뮤니티 관리</span>
            {openHomeMenu === 3 ? (
              <img className="cursorPointer" src="\img\sidebar-minus-icon.svg" />
            ) : (
              <img className="cursorPointer" src="\img\sidebar-plus-icon.svg" />
            )}
          </div>
        ) : null}

        {openHomeMenu === 3 && (
          <div className="sidebar-submenu-wrap">
            {accessAuthority.includes("comunity") ? (
              <div className="sidebar-submenu">
                <Link
                  to={"/admin/community/1"}
                  className={window.location.pathname.includes("/admin/community") ? "sidebar-submenu-active" : null}
                >
                  커뮤니티 관리
                </Link>
              </div>
            ) : null}
            {accessAuthority.includes("call") ? (
              <div className="sidebar-submenu">
                <Link
                  to={"/admin/report/1"}
                  className={window.location.pathname.includes("/admin/report") ? "sidebar-submenu-active" : null}
                >
                  신고 관리
                </Link>
              </div>
            ) : null}
          </div>
        )}
        {sessionStorage.getItem("managerAuthority") !== "MANAGER" ? (
          <div
            className="sidebar-catagory-container"
            onClick={() => {
              if (openHomeMenu === 4) {
                setOpenHomeMenu(null);
              } else {
                setOpenHomeMenu(4);
              }
            }}
          >
            <span className={openHomeMenu === 4 ? "openMenuStyle" : ""}>서비스 관리</span>
            {openHomeMenu === 4 ? (
              <img className="cursorPointer" src="\img\sidebar-minus-icon.svg" />
            ) : (
              <img className="cursorPointer" src="\img\sidebar-plus-icon.svg" />
            )}
          </div>
        ) : null}

        {openHomeMenu === 4 && (
          <div className="sidebar-submenu-wrap">
            {accessAuthority.includes("alarm") ? (
              <div className="sidebar-submenu">
                <Link
                  to={"/admin/alarm/1"}
                  className={window.location.pathname.includes("/admin/alarm") ? "sidebar-submenu-active" : null}
                >
                  알림 관리
                </Link>
              </div>
            ) : null}
            {accessAuthority.includes("notice") ? (
              <div className="sidebar-submenu">
                <Link
                  to={"/admin/notice/1"}
                  className={window.location.pathname.includes("/admin/notice") ? "sidebar-submenu-active" : null}
                >
                  공지사항 관리
                </Link>
              </div>
            ) : null}
            {accessAuthority.includes("faq") ? (
              <div className="sidebar-submenu">
                <Link
                  to={"/admin/faq/1"}
                  className={window.location.pathname.includes("/admin/faq") ? "sidebar-submenu-active" : null}
                >
                  FAQ 관리
                </Link>
              </div>
            ) : null}
            {accessAuthority.includes("popup") ? (
              <div className="sidebar-submenu">
                <Link
                  to={"/admin/popups/1"}
                  className={window.location.pathname.includes("/admin/popups") ? "sidebar-submenu-active" : null}
                >
                  팝업 관리
                </Link>
              </div>
            ) : null}
            {accessAuthority.includes("terms") ? (
              <div className="sidebar-submenu">
                <Link
                  to={"/admin/terms"}
                  className={window.location.pathname.includes("/admin/terms") ? "sidebar-submenu-active" : null}
                >
                  약관 관리
                </Link>
              </div>
            ) : null}
            {accessAuthority.includes("banner") ? (
              <div className="sidebar-submenu">
                <Link
                  to={"/admin/banner"}
                  className={window.location.pathname.includes("/admin/banner") ? "sidebar-submenu-active" : null}
                >
                  배너 관리
                </Link>
              </div>
            ) : null}
            {accessAuthority.includes("marker") ? (
              <div className="sidebar-submenu">
                <Link
                  to={"/admin/marker"}
                  className={window.location.pathname.includes("/admin/marker") ? "sidebar-submenu-active" : null}
                >
                  마커 관리
                </Link>
              </div>
            ) : null}
            {accessAuthority.includes("open-source") ? (
              <div className="sidebar-submenu">
                <Link
                  to={"/admin/opensource"}
                  className={window.location.pathname.includes("/admin/opensource") ? "sidebar-submenu-active" : null}
                >
                  오픈소스관리
                </Link>
              </div>
            ) : null}
          </div>
        )}
        {sessionStorage.getItem("managerAuthority") !== "MANAGER" ? (
          <div
            className="sidebar-catagory-container"
            onClick={() => {
              if (openHomeMenu === 5) {
                setOpenHomeMenu(null);
              } else {
                setOpenHomeMenu(5);
              }
            }}
          >
            <span className={openHomeMenu === 5 ? "openMenuStyle" : ""}>환경설정</span>
            {openHomeMenu === 5 ? (
              <img className="cursorPointer" src="\img\sidebar-minus-icon.svg" />
            ) : (
              <img className="cursorPointer" src="\img\sidebar-plus-icon.svg" />
            )}
          </div>
        ) : null}
        {openHomeMenu === 5 && (
          <div className="sidebar-submenu-wrap">
            {accessAuthority.includes("base") ? (
              <div className="sidebar-submenu">
                <Link
                  to={"/admin/information"}
                  className={window.location.pathname.includes("/admin/information") ? "sidebar-submenu-active" : null}
                >
                  기본정보 관리
                </Link>
              </div>
            ) : null}
            {accessAuthority.includes("manager") ? (
              <div className="sidebar-submenu">
                <Link
                  to={"/admin/permission/1"}
                  onClick={() => {}}
                  className={window.location.pathname.includes("/admin/permission") ? "sidebar-submenu-active" : null}
                >
                  관리자 권한 관리
                </Link>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
