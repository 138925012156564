import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import "../../css/gathering/ManageGatheringSeeMore.css";
import "../../css/Manage.css";
import ManageGatheringDetail from "./ManageGatheringdetail";
import ManageGatheringSchedule from "./ManageGatheringSchedule";
import ManageGatheringNotice from "./ManageGatheringNotice";
import ManageGatheringReview from "./ManageGatheringReview";
import ManageGatheringInquiry from "./ManageGatheringInquiry";
import AlertPopup from "../../components/AlertPopup";
import MeetingApi from "../../api/meetingApi";
import { toast } from "react-toastify";

const ManageGatheringSeeMore = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  //--삭제 state--//
  const [isOpen, setIsOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("삭제 하시겠습니까?");
  const [content, setContent] = useState("삭제된 데이터는 복구가 불가능합니다.");
  const [alertValue, setAlertValue] = useState(false);
  const [cancelTitle, setCancelTitle] = useState("취소");
  const [confirmTitle, setConfirmTitle] = useState("삭제");
  //--삭제 state--//

  const [scheduleToggle, setScheduleToggle] = useState([
    { label: "기본정보 및 소개", value: true },
    { label: "일정", value: false },
    { label: "공지", value: false },
    { label: "프로그램 리뷰", value: false },
    { label: "문의", value: false },
  ]);
  const [meetingId, setMeetingId] = useState(0);

  useEffect(() => {
    setMeetingId(params.id);

    if (location.state) {
      let stateIndex = scheduleToggle.findIndex((item) => item.label === location.state.id);
      let copyData = [...scheduleToggle];

      copyData.map((item, index) => {
        item.value = false;
      });

      if (stateIndex === -1) {
        copyData[0].value = true;
        setScheduleToggle(copyData);
        return;
      }
      copyData[stateIndex].value = true;
      setScheduleToggle(copyData);
    }
  }, [location]);

  //삭제 실행하는 곳
  const delMeeting = async () => {
    setIsOpen(true);
    setDeleteId(meetingId);
  };

  const [deleteId, setDeleteId] = useState(-1);
  useEffect(() => {
    const del = async () => {
      try {
        if (deleteId <= 0) {
          return;
        }
        await MeetingApi.delMeeting(deleteId);
        toast("삭제되었습니다.");
        navigate("/admin/gathering/1");
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    };
    if (alertValue) {
      del();
    }
  }, [alertValue]);

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-gathering-seemore">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
          <div>{scheduleToggle[0].value === true ? <a onClick={delMeeting}>삭제</a> : null}</div>
        </div>
        <div
          className={
            scheduleToggle[2].value === true ? "gathering-notice-menu-container" : "gathering-seemore-menu-container"
          }
        >
          {scheduleToggle.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  navigate(`/admin/gathering/detail/${meetingId}`, {
                    key: index,
                    state: {
                      id: item.label,
                    },
                  });
                }}
                className={item.value === true ? "gathering-menu-active" : ""}
                style={item.value === true ? { color: "#3ac8f5" } : {}}
              >
                {item.label}
              </div>
            );
          })}
        </div>
        {scheduleToggle[0].value ? <ManageGatheringDetail meetingId={meetingId} /> : null}
        {scheduleToggle[1].value ? <ManageGatheringSchedule meetingId={meetingId} /> : null}
        {scheduleToggle[2].value ? <ManageGatheringNotice meetingId={meetingId} /> : null}
        {scheduleToggle[3].value ? <ManageGatheringReview meetingId={meetingId} /> : null}
        {scheduleToggle[4].value ? <ManageGatheringInquiry meetingId={meetingId} /> : null}
        {/* 삭제 컴포넌트 */}
        <AlertPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          headerTitle={headerTitle}
          content={content}
          setAlertValue={setAlertValue}
          cancelTitle={cancelTitle}
          confirmTitle={confirmTitle}
        />
      </section>
    </div>
  );
};

export default ManageGatheringSeeMore;
