import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../../css/Manage.css";
import "../../css/main/ManageMain.css";
import MemberApi from "../../api/memberApi";
import LeaveApi from "../../api/leaveApi";
import moment from "moment";
import MainApi from "../../api/mainApi";

const ManageMain = () => {
  let navigate = useNavigate();
  const [memberNum, setMemberNum] = useState(null);
  const [leaveMemberNum, setLeaveMemberNum] = useState(null);

  //방문자수, 날짜 정보
  const [visit, setVisit] = useState([]);
  const [graphMax, setGraphMax] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  useEffect(() => {
    const getMember = async () => {
      try {
        const memberinfo = (await MemberApi.GetMemberList()).data.data;
        const visitor = (await MainApi.Get({ size: 10, page: currentPage })).data.data;
        setVisit(visitor.content);
        setTotalPages(Math.ceil(visitor.totalCount / 10));
        setMemberNum(memberinfo.totalCount);
        const Leaveinfo = (await LeaveApi.Get()).data.data.totalCount;
        setLeaveMemberNum(Leaveinfo);
      } catch (error) {
        console.log(error);
      }
    };
    getMember();
  }, []);

  const [visitLine, setVisitLine] = useState([]);
  useEffect(() => {
    let num = 0;
    let line = [];
    visit.map((item) => {
      if (num >= item.count) {
        return;
      } else {
        num = item.count;
      }
    });
    setGraphMax(num);
    let per = num / 3;
    for (let i = 0; i < 3; i++) {
      line.push(Math.round(num - per * i));
    }
    setVisitLine(line);
  }, [visit]);

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="manage-main-container">
          <span>Welcome!</span>
          <div className="main-greetings">
            <h1>안녕하세요. 관리자님 :)</h1>
            <div className="main-user-count-container">
              <div className="all-user-count-container">
                <span>전체 이용자 수</span>
                <span>{memberNum}명</span>
              </div>
              <div className="main-registration-secession">
                <span>회원(가입/탈퇴)</span>
                <span>
                  {memberNum}/{leaveMemberNum}
                </span>
              </div>
            </div>
          </div>
          <div className="main-visitant-graph-container">
            <span>방문자 통계</span>
            <div className="main-graph-container">
              <div className="graph-chevron-left">
                <img
                  src="\img\chevron-left.svg"
                  onClick={() => {
                    if (currentPage >= totalPages) {
                      return;
                    }
                    setCurrentPage(currentPage + 1);
                  }}
                />
              </div>
              <div className="main-visitant-graph">
                <div className="graph-axis-wrap">
                  {visitLine?.map((item, index) => {
                    return (
                      <div>
                        <span>{item}</span>
                        <div className="graph-axis-line"></div>
                      </div>
                    );
                  })}
                  <div>
                    <span>0</span>
                    <div className="graph-axis-line"></div>
                  </div>
                </div>
                <div className="graph-bar-wrap">
                  {visit?.map((item, index) => {
                    let today = moment(item.today).format("MM/DD");
                    return (
                      <div className="graph-bar-value">
                        <div className="graph-line-container">
                          <span className="graph-value-count">{item.count}</span>
                          <div
                            className="graph-bar"
                            style={{
                              height: `calc(183px - 183px*${1 - item.count / graphMax})`,
                              backgroundColor: `${item.numberOfElements % 2 === 0 ? "#36A6E5" : "#004098"}`,
                            }}
                          ></div>
                          {/* <div ></div> */}
                        </div>
                        <span className="graph-value-date">{today}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="graph-chevron-right">
                <img
                  className="cursorPointer"
                  src="\img\chevron-right.svg"
                  onClick={() => {
                    if (currentPage <= 1) {
                      return;
                    }
                    setCurrentPage(currentPage - 1);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="main-quick-menu">
            <span>퀵메뉴</span>
            <div className="main-quick-menu-list">
              <div className="main-quick-menu-container" onClick={() => navigate("/admin/member/1")}>
                <span>사용자 관리</span>
                <div>
                  <span>일반 회원 관리</span>
                  <img src="\img\quick-menu-arrow.svg" />
                </div>
              </div>
              <div className="main-quick-menu-container" onClick={() => navigate("/admin/spacesupport/1")}>
                <span>사용자 관리</span>
                <div>
                  <span>공간 지원 관리</span>
                  <img src="\img\quick-menu-arrow.svg" />
                </div>
              </div>
              <div
                className="main-quick-menu-container"
                onClick={() => {
                  navigate("/admin/gathering/1");
                }}
              >
                <span>운영 관리</span>
                <div>
                  <span>모임 관리</span>
                  <img src="\img\quick-menu-arrow.svg" />
                </div>
              </div>
              <div
                className="main-quick-menu-container"
                onClick={() => {
                  navigate("/admin/notice/1");
                }}
              >
                <span>서비스 관리</span>
                <div>
                  <span>공지사항 관리</span>
                  <img src="\img\quick-menu-arrow.svg" />
                </div>
              </div>
              <div className="main-quick-menu-container" onClick={() => navigate("/admin/banner")}>
                <span>서비스 관리</span>
                <div>
                  <span>배너 관리</span>
                  <img src="\img\quick-menu-arrow.svg" />
                </div>
              </div>
              <div className="main-quick-menu-container" onClick={() => navigate("/admin/faq/1")}>
                <span>서비스 관리</span>
                <div>
                  <span>FAQ 관리</span>
                  <img src="\img\quick-menu-arrow.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <img id="main-circle-1" src="\img\main-circle1.svg" />
      <img id="main-circle-2" src="\img\main-circle2.svg" />
    </div>
  );
};

export default ManageMain;
