import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../../css/faq/ManageFaqSeeMoreAdit.css";
import "../../css/Manage.css";
import FaqApi from "../../api/faqApi";
import { toast } from "react-toastify";

const ManageFaqSeeMoreAdd = () => {
  const [faqCatagory, setFaqCategory] = useState([]);
  const [selectMenu, setSelectMenu] = useState(false);
  const [selectMenuText, setSelectMenuText] = useState("카테고리를 선택하세요");
  const [faqInfo, setFaqInfo] = useState({
    title: "",
    content: "",
    managerId: null,
    faqCategoryId: null,
  });

  useEffect(() => {
    const getCategory = async () => {
      try {
        const categoryList = (await FaqApi.GetCategory()).data.data.content;
        // const arr = [];
        // categoryList.map((item) => {
        //   arr.unshift(item);
        // });
        setFaqCategory(categoryList);
        setFaqInfo({
          ...faqInfo,
          managerId: sessionStorage.getItem("id"),
        });
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    };
    getCategory();
  }, []);

  const faqAddHandler = async () => {
    try {
      await FaqApi.PostFaq(faqInfo);
      toast("추가되었습니다.");
      navigate(-1)
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  let navigate = useNavigate();

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div
          className="go-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <Link>
            <img src="\img\chevron-left.svg" />
            <span>뒤로가기</span>
          </Link>
        </div>
        <div className="Manage-form manage-form-faq-see-more-adit">
          <span>추가하기</span>
          <div className="Manage-input-container">
            <p className="Manage-input-title">카테고리</p>
            <div className="select">
              <div
                className="selected"
                onClick={() => setSelectMenu(!selectMenu)}
              >
                <div
                  className={
                    selectMenuText === "카테고리를 선택하세요"
                      ? "selected-value-basic"
                      : "selected-value"
                  }
                >
                  {selectMenuText}
                </div>
                <div className="select-toggle-btn">
                  <img src="\img\table-togle.svg" />
                </div>
              </div>
              {selectMenu ? (
                <ul className="option-container opt-con-faq-add">
                  {faqCatagory.map((item, index) => {
                    return (
                      <li
                        className="option"
                        onClick={() => {
                          setSelectMenuText(item.title);
                          setFaqInfo({
                            ...faqInfo,
                            faqCategoryId: item.id,
                          });
                          setSelectMenu(false);
                        }}
                      >
                        {item.title}
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </div>
          </div>
          <div className="Manage-input-container">
            <p className="Manage-input-title">제목</p>
            <input
              className="Manage-input-textarea manage-form-faq-see-more-input"
              type="text"
              placeholder="제목을 입력하세요"
              onChange={(e) => {
                setFaqInfo({
                  ...faqInfo,
                  title: e.target.value,
                });
              }}
            />
          </div>
          <div className="Manage-input-container Manage-input-container-maintext">
            <p className="Manage-input-title">본문</p>
            <textarea
              className="Manage-input-textarea manage-form-faq-see-more-textarea"
              placeholder="내용을 입력하세요"
              onChange={(e) => {
                setFaqInfo({
                  ...faqInfo,
                  content: e.target.value,
                });
              }}
            ></textarea>
          </div>{" "}
          <div className="Manage-form-save manage-form-save-faq-see-more-adit">
            <button onClick={faqAddHandler} className="cursorPointer">
              저장하기
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageFaqSeeMoreAdd;
