import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../../css/notice/ManageNoticeSeeMore.css";
import "../../css/Manage.css";
import NoticeApi from "../../api/noticeApi";
import moment from "moment";
import { toast } from "react-toastify";
import AlertPopup from "../../components/AlertPopup";

const ManageNoticeSeeMore = () => {
  let navigate = useNavigate();
  let location = useLocation();
  //--삭제 state--//
  const [isOpen, setIsOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("삭제 하시겠습니까?");
  const [content, setContent] = useState("삭제된 데이터는 복구가 불가능합니다.");
  const [alertValue, setAlertValue] = useState(false);
  const [cancelTitle, setCancelTitle] = useState("취소");
  const [confirmTitle, setConfirmTitle] = useState("삭제");
  //--삭제 state--//

  const [noticeId, setNoticeId] = useState(null);

  const [noticeInfo, setNoticeInfo] = useState({
    title: null,
    createdAt: null,
    email: null,
    content: null,
  });

  useEffect(() => {
    if (location.state) {
      const { id } = location.state;
      setNoticeId(location.state.id);
      getNoticeInfo(id);
    }
  }, [location]);

  const getNoticeInfo = async (id) => {
    try {
      const info = (await NoticeApi.GetNotice(id)).data.data.content;
      let createDate = moment(info.createdAt).utc().format("YYYY/MM/DD MM:MM:MM");
      setNoticeInfo({
        ...noticeInfo,
        title: info.title,
        createdAt: createDate,
        email: info.manager.email,
        content: info.content,
      });
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  //삭제 noticeDelHandler 곳
  const noticeDelHandler = async () => {
    setIsOpen(true);
    setDeleteId(location.state.id);
  };

  const [deleteId, setDeleteId] = useState(-1);
  useEffect(() => {
    const del = async () => {
      try {
        if (deleteId <= 0) {
          return;
        }
        await NoticeApi.DelNotice(deleteId);
        toast("삭제되었습니다.");
        navigate("/admin/notice/1");
      } catch (error) {
        toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
      }
    };
    if (alertValue) {
      del();
    }
  }, [alertValue]);

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <div className="go-back-container-notice-seemore">
          <div
            className="go-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Link>
              <img src="\img\chevron-left.svg" />
              <span>뒤로가기</span>
            </Link>
          </div>
          <div>
            <Link onClick={noticeDelHandler}>삭제</Link>
            <Link to={`/admin/notice/detail/edit/${noticeId}`} state={{ id: noticeId }}>
              수정하기
            </Link>
          </div>
        </div>
        <div className="Manage-form manage-form-notice-see-more">
          <span>상세보기</span>
          <div className="Manage-form-view-details">
            <span>제목</span>
            <span>{noticeInfo.title}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>게시일자</span>
            <span>{noticeInfo.createdAt}</span>
          </div>
          <div className="Manage-form-view-details">
            <span>게시 관리자 이메일</span>
            <span>{noticeInfo.email}</span>
          </div>
          <div className="Manage-form-view-details manage-flex-start">
            <span>본문</span>
            <span>{noticeInfo.content}</span>
          </div>
        </div>
        {/* 삭제 컴포넌트 */}
        <AlertPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          headerTitle={headerTitle}
          content={content}
          setAlertValue={setAlertValue}
          cancelTitle={cancelTitle}
          confirmTitle={confirmTitle}
        />
      </section>
    </div>
  );
};

export default ManageNoticeSeeMore;
