import React, { useEffect, useState } from "react";
import "../../css/marker/ManageMarker.css";
import "../../css/Manage.css";
import MarkerApi from "../../api/marker";
import { toast } from "react-toastify";

const ManageMarker = () => {
  const [markerInfo, setMarkerInfo] = useState([]);

  useEffect(() => {
    getMarker();
  }, []);

  const getMarker = async () => {
    try {
      const info = (await MarkerApi.Get()).data.data.content;
      const temp = info.map((item) => {
        return {
          ...item,
          imageDeleteFlag: false,
          selectImageDeleteFlag: false,
        };
      });
      setMarkerInfo(temp);
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const saveImgFile = (e, index) => {
    let copyData = [...markerInfo];
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      copyData[index].binaryUrl = file;
      copyData[index].imageUrl = e.target.result;
      copyData[index].imageDeleteFlag = true;
      setMarkerInfo(copyData);
    };
    reader.readAsDataURL(file);
    e.target.value = "";
  };

  const saveSelectImgFile = (e, index) => {
    let copyData = [...markerInfo];
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      copyData[index].selectBinaryUrl = file;
      copyData[index].selectImageUrl = e.target.result;
      copyData[index].selectImageDeleteFlag = true;
      setMarkerInfo(copyData);
    };
    reader.readAsDataURL(file);
    e.target.value = "";
  };

  const deleteImage = (e, index) => {
    let copyData = [...markerInfo];
    copyData[index].imageUrl = "";
    copyData[index].imageDeleteFlag = true;
    setMarkerInfo(copyData);
  };

  const deleteSelectImage = (e, index) => {
    let copyData = [...markerInfo];
    copyData[index].selectImageUrl = "";
    copyData[index].selectImageDeleteFlag = true;
    setMarkerInfo(copyData);
  };

  const markerEdit = async (id, index) => {
    try {
      const formData = new FormData();
      formData.append("spaceCategory", markerInfo[index].spaceCategory);
      if (markerInfo[index].binaryUrl) {
        formData.append("image", markerInfo[index].binaryUrl);
      }
      if (markerInfo[index].selectBinaryUrl) {
        formData.append("selectImage", markerInfo[index].selectBinaryUrl);
      }
      formData.append("imageDeleteFlag", markerInfo[index].imageDeleteFlag);
      formData.append(
        "selectImageDeleteFlag",
        markerInfo[index].selectImageDeleteFlag
      );
      await MarkerApi.Patch(id, formData);
      toast("저장되었습니다.");
    } catch (error) {
      toast("서버에 문제가있습니다. 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <div className="Manage-container">
      <section className="Manage-contents">
        <span>마커 관리</span>
        {markerInfo.map((item, index) => {
          const inputId = `img-input-${index}`;
          const selectInputId = `select-img-input-${index}`;
          return (
            <div className="Manage-form manage-form-marker" key={index}>
              <span className="ManageMarker-form-title">
                {item.spaceCategory === "CAFE"
                  ? "카페·제과"
                  : item.spaceCategory === "RESTAURANT"
                  ? "맛집"
                  : item.spaceCategory === "PUB"
                  ? "술집"
                  : item.spaceCategory === "TOUR"
                  ? "관광지"
                  : item.spaceCategory === "LIFE"
                  ? "생활"
                  : item.spaceCategory === "EXPERIENCE"
                  ? "체험"
                  : item.spaceCategory === "SHOP"
                  ? "SHOP"
                  : item.spaceCategory === "ETC"
                  ? "기타"
                  : null}
              </span>
              <div className="ManageMarker-form-img">
                <div className="ManageMarker-form-basic">
                  <span>기본 디자인</span>
                  <div className="marker-input-img-container">
                    <label htmlFor={inputId}>
                      <img src="\img\img-registration.svg" />
                      <span>이미지 등록</span>
                    </label>
                    <input
                      id={inputId}
                      className="Manage-input-textarea"
                      type="file"
                      required
                      onChange={(e) => {
                        saveImgFile(e, index);
                      }}
                    />
                    {item.imageUrl ? (
                      <div className="marker-img">
                        <img className="uploaded-img" src={item?.imageUrl} />
                        <img
                          src="\img\image-cancel.svg"
                          onClick={(e) => deleteImage(e, index)}
                        />
                      </div>
                    ) : null}
                  </div>
                  <span>*권장사이즈 96*114</span>
                </div>
                <div className="ManageMarker-form-select">
                  <span>선택된 디자인</span>
                  <div className="marker-input-img-container">
                    <label htmlFor={selectInputId}>
                      <img src="\img\img-registration.svg" />
                      <span>이미지 등록</span>
                    </label>
                    <input
                      id={selectInputId}
                      className="Manage-input-textarea"
                      type="file"
                      required
                      onChange={(e) => {
                        saveSelectImgFile(e, index);
                      }}
                    />
                    {item.selectImageUrl ? (
                      <div className="marker-img">
                        <img
                          className="uploaded-img"
                          src={item?.selectImageUrl}
                        />
                        <img
                          src="\img\image-cancel.svg"
                          onClick={(e) => deleteSelectImage(e, index)}
                        />
                      </div>
                    ) : null}
                  </div>
                  <span>*권장사이즈 144*177</span>
                </div>
              </div>
              <div className="Manage-form-save Manage-form-save-marker">
                <button
                  className="cursorPointer"
                  onClick={() => markerEdit(item.id, index)}
                >
                  저장하기
                </button>
              </div>
            </div>
          );
        })}
      </section>
    </div>
  );
};

export default ManageMarker;
